////
/// Styles for the news-list component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/text-field";
@use "~@make/ds2/scss/components/button";
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";


.news-list-wrapper {
  .news-list {
    @include indent-outer-bottom(-40);
  }

  .news {
    @include indent-outer-bottom(40);
  }
}

.news-list {
  @include grid-standard;
  display: grid;

  .news {
    @include indent-outer-bottom(40);
    grid-column: span 2;

    @include screen-small-down {
      margin-bottom: 20px;
    }
  }
}

.news-list--main {
  @include screen-medium-up {
    // Negative margins compensate the .news blocks margin-bottom values,
    // paddings values determine the offset between .news-list--main and
    // the .full-width-banner:
    margin-bottom: -40px;
    padding-bottom: 64px;
  }

  .news {
    &:not(.news--important):not(.news--banner) {
      margin-bottom: 28px;

      @include screen-medium-up {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-dividing-line-light;
      }
    }

    &:not(.news--important):not(.news--banner):hover {
      border-color: $color-dividing-line;
    }

    &:not(.news--banner):last-of-type {
      border-bottom: none;
    }

    @include screen-medium-up {
      &:not(.news--banner):nth-last-of-type(-n + 2) {
        border-bottom: none;
      }
    }

    @include screen-large-up {
      &:not(.news--banner):nth-last-of-type(-n + 3) {
        border-bottom: none;
      }
    }
  }

  .news--important {
    @include indent-outer-bottom(40);

    @include screen-medium-up {
      grid-column: span 4;
      grid-row: span 2;
    }
  }

  .news--banner {
    @include indent-outer-bottom(32);

    @include screen-large-up {
      grid-column: 5 / span 2;

      &:first-of-type {
        grid-row: 1;
      }

      &:nth-of-type(2) {
        grid-row: 2;
        // TODO: find a better way to handle this:
        margin-bottom: 40px;
      }
    }
  }
}
