////
/// Styles for the government popular services component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";


.gov-popular-services {
  @include indent-top(40);
  //   @include indent-outer-bottom(-32);
  @include grid-standard;
  display: grid;
}

.gov-popular-services__item {
  position: relative;
  @include indent-bottom(32);
  grid-column: span 2;
  display: flex;
  gap: 0;
}

.gov-popular-services__link {
  @include indent-all(32);
  @extend %typo-text-link-external-url-arrow;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  border-radius: 2px;
  transition: box-shadow 0.1s linear;

  @include screen-medium-up {
    min-height: 180px;
  }

  &[href^="http"]:after {
    @include indent-base(16, top right);
    left: auto;
  }

  &:hover {
    box-shadow: 0 16px 32px rgba($color-heading, 0.04);

    .gov-popular-services__title {
      color: var(--color-interactive-element-hover);
    }
  }

  &:focus {
    .gov-popular-services__title {
      @extend %typo-base-text-link-focused;
    }
  }
}

.gov-popular-services__link--new {
  &:before {
    @include indent-base(16, top);
    @include indent-base(32, left);
    position: absolute;
    color: $color-warning;
    font-size: 12px;
    line-height: typo-text-line-height(12px);
    content: attr(data-new-text);
  }

  .gov-popular-services__title {
    @include screen-small-down {
      margin-top: 4px;
    }
  }
}

.gov-popular-services__title {
  @extend %typo-base-text-link-transition;
  @include indent-outer-bottom(24);
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  font-weight: 500;
  outline-color: transparent;
}

.gov-popular-services__category {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  display: flex;
  gap: 0;
  align-items: center;
  margin-top: auto;
  color: $color-hint;

  .icon {
    @include indent-outer-right(12);
    width: 20px;
    height: 20px;
  }
}

.gov-popular-services__category-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0;
  margin-top: auto;
}

.gov-popular-services__category-icon {
  width: 32px;
  height: 32px;
  background-size: cover;
}

.gov-popular-services__item--with-cover-bg {
  .gov-popular-services__category-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
}

.gov-popular-services__item--height2 {
  .gov-popular-services__link {
    min-height: 392px;
  }
}
