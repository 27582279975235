////
/// Styles for the news-quote component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/button";
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";
@use "block-link";
@use "news";


.news--block {
  @extend .block-link;

  @include screen-small-down {
    padding: 0;

    &:hover {
      box-shadow: none;
      outline: none;
    }
  }

  @include screen-medium-up {
    @include indent-top(32);
    @include indent-horizontal-pair(32);
    @include indent-bottom(32);
  }

  &::before {
    @include screen-small-down {
      border-color: transparent;
    }
  }

  &:hover {
    .news__description {
      color: $color-text;
    }
  }

  .news__type {
    color: $color-hint;
  }

  .news__title {
    @include typo-make-sizes($typo-paragraph-lead-sizes);
    @include screen-small-down {
      font-size: 16px;
      line-height: 24px;
    }

    &:after {
      color: $color-hint;
      order: 0;
      content: attr(data-prefix-text);
    }
  }

  .news__description {
    // @include indent-outer-top(16);
    order: 2;
    margin-top: auto;
    color: $color-hint;
    transition: color 0.1s linear;

    @include screen-small-down {
      display: none;
    }
  }

}
