////
/// The "Back link" component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
/// @link http://standart.gov.design/design/back-link
////

@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";

.back-link {
  @extend %typo-base-text-link;
  color: var(--color-interactive-element);
  display: inline-flex;
  align-items: center;
  gap: 0;
  // Non-standard offsets for "visual compensation":
  margin-left: -7px;

  @include screen-medium-up {
    margin-left: -31px;
  }

  .icon {
    fill: var(--color-interactive-element);
    margin-right: 8px;
    transform: rotate(90deg);
    transition: color 0.1s linear, transform 0.1s linear;
  }

  &:hover {
    color: var(--color-interactive-element-hover);

    .icon {
      transform: rotate(90deg) translateY(4px);
    }
  }

  &:active {
    color: var(--color-interactive-element-active)
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}
