////
/// Styles for the site menu modal window component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
/// @todo major refactor
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";


.site-menu-modal {
  @include indent-outer-left(48);
  position: absolute;
  //   top: 100%;
  left: 0;
  right: 0;
  height: 100%;
  pointer-events: none;
  z-index: -2;
  overflow: hidden;
  color: $color-text;

  @include screen-medium-up {
    margin-left: 0;
    top: 100%;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-dividing-line, 0.8);
    opacity: 0;
    transition: opacity 0.15s linear;
    z-index: -1;
    content: "";
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-light-gray-fill;
    z-index: -1;
    transition: transform 0.25s ease-out 0.1s, opacity 0.1s linear;
    transform: translateY(50%);
    opacity: 0;
    content: "";
  }

  &.active {
    pointer-events: auto;

    &:before {
      opacity: 1;
    }

    &:after,
    .site-menu-modal__content-wrapper {
      transition-delay: 0s, 0s;
      transform: translateY(0);
      opacity: 1;
    }

    .site-submenu {
      pointer-events: auto;
      z-index: 1;
    }

    .site-submenu__column {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.site-menu-modal--submodal {
  top: 0;
  transition: opacity 0.1s linear;
  opacity: 0;

  &:after {
    transform: translateY(0);
  }

  &.active {
    opacity: 1;
  }
}

.site-menu-modal__content-wrapper {
  @extend %helper-hidden-system-scrollbar;
  position: relative;
  height: 100%;
  transition: transform 0.25s ease-out 0.1s, opacity 0.1s linear;
  transform: translateY(50%);
  overflow-y: scroll;
  opacity: 0;
}

.site-menu-modal__content {
  @include indent-vertical-pair(32);
  @include grid-container;
  @include grid-standard;
  @include typo-make-sizes($typo-paragraph-text-sizes);
  position: relative;

  .site-search {
    @include screen-medium-up {
      grid-column: span 4;
    }
  }

  .site-map {
    @include indent-top(40);

    @include screen-medium-up {
      grid-column: span 4;
    }
    @include screen-large-up {
      grid-column: span 6;
    }
  }
}

.site-menu-modal__heading {
  @include indent-bottom(24);
  grid-column: span 2;
  padding-top: 8px;

  @include screen-small-down {
    padding-right: 24px;
  }

  @include screen-medium-up {
    grid-column: span 4;
  }
}

.site-menu-modal__close {
  @include indent-base(24, top right);
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 1;

  .wave--active {
    background-color: $color-dividing-line;
  }
}

.site-menu-modal__content-search-block-wrapper {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #D9D9DC;
}

.site-menu-modal__content-search-input {
  height: 56px;
}

.site-menu-modal__content-search-block {
  grid-column-start: 1;
  grid-column-end: 5;
  position: relative;

  .constructor-input-field__label-wrapper {
    display: none;
  }
}

.site-menu-modal__content-search-button {
  position: absolute;
  top: 16px;
  right: 16px;

  .site-menu__icon-button {
    svg {
      fill: #ADADB4;
    }
  }
}