@use '~@make/ds2/scss/mixins' as *;
@use '~@make/ds2/scss/variables' as *;

.constructor-structured-list__child-list {
  display: grid;
  @include grid-medium-up(2 1);
}

.constructor-structured-list__item {
  @include indent-outer-bottom(16);

  .constructor-elements-list {
    margin-bottom: 0;
  }
}

.constructor-structured-child-list-item__wrapper {
  position: relative;
  @include indent-outer-left(12);

  .constructor-dropdown-button {
    position: absolute;
    left: -12px;
    top: 30px;
  }

  .constructor-elements-list__button {
    opacity: 0;
    transition: opacity 0.15s ease;
  }

}

.constructor-structured-child-list-item__item {
  display: flex;
  align-items: center;
  gap: 0;

  &:hover {
    .constructor-structured-child-list-item {
      background-color: $color-white;
      box-shadow: 0 16px 32px rgba($color-heading, 0.04);
      border: none;
    }

    .constructor-elements-list__button {
      opacity: 1;
    }
  }

}

.constructor-structured-child-list-item__add-button {
  @include indent-outer-right(44);
}

.constructor-structured-child-list-item {
  display: flex;
  align-items: center;
  height: 48px;
  @include indent-outer-right(20);
  @include indent-left(16);
  @include indent-right(24);
  border: 1px solid $color-dividing-line;
  flex-grow: 1;
  transition: background-color 0.15s ease;
}

.constructor-structured-child-list-item__link {
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;
}

.constructor-structured-child-list.level-1 {
  & > .constructor-structured-child-list-item__wrapper {
    margin-left: 0;
  }
}

.constructor-structured-child-list.level-2 {
  @include typo-make-sizes($typo-paragraph-nav-sizes);

  & .constructor-structured-child-list-item__wrapper {
    @include indent-top(16);
    @include indent-left(32);

    &:before {
      position: absolute;
      left: 0;
      top: 40px;
      height: 1px;
      width: 32px;
      background-color: $color-dividing-line;
      content: '';
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: $color-dividing-line;
      content: '';
    }

    &:last-child:after {
      height: 40px;
    }
  }

}

.constructor-structured-child-list.level-3 {
  @include typo-make-sizes($typo-paragraph-small-sizes);

  .constructor-structured-child-list-item__wrapper {
    @include indent-left(20);
    @include indent-top(12);

    &:before {
      top: 36px;
      width: 20px;
    }

    &:last-child:after {
      height: 36px;
    }

  }
}
