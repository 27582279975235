@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.page-news {
  .page-section__header {
    @include indent-inner-top(48);

    + .news-list__wrapper {
      @include screen-medium-up {
        @include indent-outer-top(-24);
      }
    }
  }
}