@use '~@make/ds2/scss/variables' as *;
@use '~@make/ds2/scss/mixins' as *;

.constructor-management {

  .constructor-structured-child-list-item {
    display: block;
    height: 100%;
    @include indent-top(24);
    @include indent-bottom(32);
    @include indent-horizontal-pair(24);
    margin-right: 0;
  }

  .constructor-structured-child-list-item__item:hover {
    .constructor-structured-child-list-item {
      background-color: transparent;
      border: 1px solid $color-dividing-line;
      box-shadow: none;
    }
  }

  .constructor-structured-child-list-item__add-button {
    margin: 0;
  }

  .constructor-elements-list__button {
    opacity: 1;
  }

}
