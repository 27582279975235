////
/// Styles for the custom "Site Search" component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";


.accordion {
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    top: 0;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background-color: $color-white;
    transition: opacity 0.1s linear;
    box-shadow: 0 16px 32px rgba($color-heading, 0.04);
    opacity: 0;
    z-index: -1;
    content: "";
  }

  &:last-of-type {
    .accordion__button {
      border-bottom: none;
    }
  }

  &.opened {
    &:before {
      opacity: 1;
    }

    .accordion__button .icon {
      transform: rotate(180deg);
      fill: $color-hint;
    }

    .accordion__content-wrapper {
      transition-delay: 0.05s, 0.25s;
      transition-duration: 0.2s, 0.175s;
      opacity: 1;
    }
  }

  .wave-wrapper {
    left: 4px;
    right: 4px;
  }

  .wave--active {
    background-color: $color-white;
  }
}

.accordion--compact {
  .accordion__button {
    @include typo-make-sizes($typo-paragraph-nav-sizes);
    @include indent-vertical-pair(20);
  }

  .accordion__content {
    @include indent-top(24);
    @include indent-bottom(48);
  }
}

.accordion__wrapper {
  @include grid-container;
}

.accordion__button {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  @include indent-vertical-pair(32);
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid $color-dividing-line-light;
  transition: border-color 0.1s linear;
  gap: 0;

  &:hover {
    border-color: $color-dividing-line;

    .icon {
      fill: $color-hint;
    }

    .accordion__text {
      color: $color-interactive-element-hover;
    }
  }

  &:active {
    .accordion__text {
      color: $color-interactive-element-active;
    }
  }

  &:not(.admin-accordion) {
    &:hover {
      .accordion__text {
        color: var(--color-interactive-element-hover);
      }
    }

  }

  .icon {
    fill: $color-field-frame;
    transition: fill 0.1s linear, transform 0.1s linear;
  }
}

.accordion__icon {
  @include indent-base(32, width);
  @include indent-outer-right(16);
  flex-shrink: 0;
  align-self: flex-start;
}

.accordion__text {
  @extend %typo-base-text-link-transition;
  margin-right: 4px;
  color: $color-heading;
}

.accordion__content-wrapper {
  transition: max-height 0.2s ease-out, opacity 0s linear;
  max-height: 0;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  will-change: max-height;
  padding-left: 8px;
  margin-left: -8px;
  padding-right: 8px;
  margin-right: -8px;
}

.accordion__content {
  @include indent-top(40);
  @include indent-bottom(64);

  .person-info-list {
    &:not(:last-child) {
      @include indent-bottom(64);
    }
  }

  .important-links {
    &:not(:last-child) {
      @include indent-bottom(48);
    }
  }
}

.accordion__content-heading {
  @include indent-bottom(32);
}

.accordion {
  .important-links {
    @include indent-outer-top(-20);
    @include grid-medium-up(1 1) {
      display: grid;
    }
  }

  .important-links__item {
    @include indent-top(20);

    @include screen-medium-up {
      &:nth-last-child(2):not(:first-child) {
        .important-links__link:before,
        .illustrated-link:after {
          display: none;
        }
      }
    }

    &:nth-child(2):last-child {
      grid-row: 2;
    }
  }

  .important-links__link {
    @include typo-make-sizes($typo-paragraph-nav-sizes);
    display: inline;
    color: $color-text;

    &:hover {
      color: var(--color-interactive-element-hover);
    }

    &:active {
      color: $color-interactive-element-active;
    }

    &:before {
      pointer-events: none;
    }

    &:after {
      display: inline-flex;
      position: static;
    }
  }
}

.accordions-wrapper + .page-section {
  @include indent-top(80);
}
