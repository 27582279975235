////
/// Styles for the popups windows.
////

.disclaimer {
  position: fixed;
  width: 100%;
  padding: 24px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  z-index: 100;
  cursor: default;
  transition: opacity 0.3s 0s linear;
  opacity: 0;
  pointer-events: none;
  max-width: 360px;
  bottom: 16px;
  right: 16px;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.08);
}

.disclaimer--disabled {
  display: none;
}

.disclaimer--visible {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 1s;
}

.disclaimer__close {
  display: block;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #007bff;
  cursor: pointer;
}
