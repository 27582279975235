@use '~@make/ds2/scss/variables' as *;
@use '~@make/ds2/scss/mixins' as *;

.constructor-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $color-white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 30, 0.08);
  transition: all 0.15s ease;
  z-index: 2;

  &.active {
    background-color: $color-text;
    transform: rotate(180deg);

    .constructor-dropdown-icon {
      fill: $color-white;
    }
  }
}

.constructor-dropdown-icon {
  width: 10px;
  height: 6px;
}
