@use "~@make/ds2/scss/variables/colors" as *;

.link {
  position: relative;
  color: $color-interactive-element;

  &:not(.admin-link) {
    color: var(--color-interactive-element);

    &:before {
      border-bottom-color: var(--color-interactive-element-rgba);
    }

    &:hover:before {
      border-color: var(--color-interactive-element);
    }
  }

  &:before {
    position: absolute;
    height: 1px;
    border-bottom-color: $color-interactive-element-outline;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    left: 0;
    right: 0;
    bottom: -1px;
    content: '';
    transition: border-color 0.15s ease;
  }

  &:hover:before {
    border-color: $color-interactive-element;
  }
}

.text-link {
  color: var(--color-interactive-element);
}
