@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";

.constructor-settings-main-page__title {
  & > .page-heading {
    @include indent-bottom(16);
  }

  & > .page__section-description {
    padding-top: 0;
  }
}

.constructor-settings-main-page__subtitle {
  @include indent-bottom(32);

  & > .page__section-title {
    @include indent-bottom(16);
  }

  & > .page__section-description {
    padding-top: 0;
  }
}

.constructor-form__row {
  @include indent-bottom(24);
}

.constructor-form__button-wrapper {
  display: flex;
  align-items: center;
}

.constructor-form__cancel-button {
  font-size: 16px;
  line-height: 24px;
  @include indent-outer-left(20);
}
