@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;

.constructor-input-field__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 24px;

  & input,
  textarea,
  .constructor-input-field__editor,
  .constructor-input-field__contenteditable {
    min-height: 48px;
    border: none;
    width: 100%;
    border-radius: 2px;
    padding: 12px 16px;

    &:focus,
    &.focus {
      box-shadow: 0 0 0 4px $color-interactive-element-outline;
      transition: box-shadow 0.1s linear;
    }

    &:not(.admin-input) {
      &:focus,
      &.focus {
        box-shadow: 0 0 0 4px var(--color-interactive-element-rgba);
      }
    }


    &::placeholder {
      color: $color-hint;
    }
  }

  & .textarea {
    min-height: 80px;
  }

  & .textarea--small {
    .editor__input {
      min-height: 80px;
    }
  }

  & .textarea--medium {
    .editor__input {
      min-height: 320px;
    }
  }

  & .textarea--big {
    .editor__input {
      min-height: 60vh;
    }
  }

  .textarea--medium,
  .textarea--small {
    & > .medium-editor-element {
      @include indent-top(8);
    }
  }

  .medium-editor-element {
    a {
      color: var(--color-interactive-element);

      &:hover {
        color: var(--color-interactive-element-hover);
      }
    }
  }

  &:before {
    background-color: $color-error-background;
    position: absolute;
    top: 0;
    left: -16px;
    right: -16px;
    bottom: 0;
    content: '';
    opacity: 0;
    transition: opacity 0.15s ease;
  }

  &.error {
    &:before {
      opacity: 1;
    }

    @include indent-top(8);
    @include indent-bottom(16);

    input,
    textarea {
      box-shadow: 0 0 0 2px $color-error;
      transition: box-shadow 0.1s linear;
    }

  }
}

.constructor-input-field__editor,
.constructor-input-field__contenteditable {
  position: relative;
  display: block;
  background-color: #fff;
  font-size: 16px;
  line-height: 24px;
  height: auto;
}

.constructor-input-field__contenteditable {
  & p:not(:last-child),
  & div:not(:last-child) {
    @include indent-outer-bottom(20);
  }
}

.constructor-input-field__label-wrapper {
  padding-bottom: 8px;
}

.constructor-input-field__hint-dot {
  position: absolute;
  right: 0;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $color-field-frame;
  cursor: pointer;
}

.constructor-input-field__label {
  position: relative;
  padding-right: 10px;
}

.constructor-input-field__label-description {
  font-size: 14px;
  line-height: 20px;
  color: #71717C;
}

.constructor-input-field__input {
  position: relative;

  &:not(.admin-input) {
    &.focused:after {
      box-shadow: 0 0 0 4px var(--color-interactive-element-rgba);
    }
  }

  &.focused:after {
    box-shadow: 0 0 0 4px $color-interactive-element-outline;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
    transition: box-shadow 0.1s linear;
    z-index: 3;
    border-radius: 1px;
    pointer-events: none;
  }
}

.constructor-input-field__error-message {
  display: block;
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-error-text;
}

.constructor-input-field__editor > .medium-editor__toolbar-wrapper {
  margin-bottom: 20px;
}

.editor__input {
}
