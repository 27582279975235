////
/// Styles for the page-section component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";

$typo-heading-h2-mainpage-sizes: (24, 24, 28, 36, 40) !default;

.page-section {
  @include grid-container;

  .news-list {
    @include indent-outer-bottom(-40);

    @include screen-small-down {
      margin-bottom: 0;
    }
  }

  .gov-popular-services {
    @include indent-outer-top(-40);
  }

  .useful-links {
    @include indent-outer-top(-20);
    @include indent-outer-bottom(-48);
    padding-top: 0;

    .spoiler__button {
      @include indent-outer-top(12);
    }
  }
}

.page-section--single {
  @include indent-outer-bottom(80);
}

h2.page-section__heading {
  @include typo-make-sizes($typo-heading-h2-mainpage-sizes, $isHeading: true);
}

.page-section__header,
.page-section__heading {
  @include indent-bottom(32);
}

.page-section__header {
  display: flex;
  align-items: flex-end;

  .page-section__heading {
    padding-bottom: 0;
  }
}

.page-section__lead {
  @include typo-make-sizes($typo-paragraph-lead-big-sizes);
  @include indent-bottom(32);
  border-bottom: 1px solid $color-dividing-line;

  a {
    @extend %typo-base-text-link;
    @extend %typo-text-link-external-url-arrow;
    @extend %typo-base-text-link-underline;
    @extend %typo-base-text-link-underlined;
    border-bottom-color: rgba($color: var(--color-interactive-element), $alpha: 0.32);

    &[href^="http"] {
      margin-right: 12px;
    }
  }
}

.page-section__heading-link {
  @extend %typo-base-text-link;
  @extend %typo-base-text-link-underline;
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  margin-left: auto;

  &:hover {
    @extend %typo-base-text-link-underlined;
  }
}

// TODO: remove this crutch
.site-secondary-menu__section + .page-section {
  @include indent-top(40);
}

.page-section__title {
  @include indent-bottom(24);
}
