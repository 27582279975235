@use '~@make/ds2/scss/placeholders';

.content-block--editor {
  ul:not([class]), ol:not([class]) {
    @extend %typo-base-text-list;
  }

  ul:not([class]) {
    @extend %typo-unordered-text-list;
    @extend %typo-unordered-text-list-nested;

    > li > ol {
      @extend %typo-ordered-text-list;
      @extend %typo-ordered-text-list-nested;
    }

    > li > ul > li > ol {
      @extend %typo-ordered-text-list;
    }

    > li > ol > li > ul,
    > li > ul > li > ul {
      @extend %typo-unordered-text-list;
    }
  }

  ol:not([class]) {
    @extend %typo-ordered-text-list;
    @extend %typo-ordered-text-list-nested;

    > li > ul {
      @extend %typo-unordered-text-list;
      @extend %typo-unordered-text-list-nested;
    }

    > li > ol > li > ul {
      @extend %typo-unordered-text-list;
    }

    > li > ol > li > ol,
    > li > ul > li > ol {
      @extend %typo-ordered-text-list;
    }
  }
}

.constructor-block__container-public {
  background-color: #f1f1f2;

  .page-public {
    max-width: none;
  }

  .constructor__work-zone-wrapper {
    padding-top: 0;
    margin-top: 0;
  }

  .page-public--main {
    .gallery-block__wrapper {
      .constructor-component__wrapper {
        @extend %grid-container-base;
      }
    }
  }
}
