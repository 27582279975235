////
/// Styles for the site modal window component.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";


.modal-window {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: -2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-dividing-line, 0.8);
    opacity: 0;
    transition: opacity 0.15s linear;
    z-index: -1;
    content: "";
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: transform 0.25s ease-out 0.1s, top 0.25s ease-out 0.1s, opacity 0.1s linear;
    transform: translateY(50%);
    opacity: 0;
    content: "";
  }

  &.active {
    top: 0;
    z-index: 2;
    pointer-events: auto;

    &.modal-window__sticky {
      @include screen-small-down {
        top: 56px;
      }
    }

    &:before {
      opacity: 1;
    }

    &:after,
    .modal-window__wrapper {
      transition-delay: 0s, 0s;
      transform: translateY(0);
      opacity: 1;
    }

    .modal-window__icon-button {
      z-index: 1;
      opacity: 1;
    }
  }
}

.modal-window__sticky {
  @include screen-small-down {
    height: calc(100% - 56px);
  }
}

.modal-window__modal-close {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal-window__wrapper {
  @extend %helper-hidden-system-scrollbar;
  margin: auto 4px 4px;
  position: relative;
  height: auto;
  background-color: $color-light-gray-fill;
  transition: transform 0.25s ease-out 0.1s, opacity 0.1s linear;
  transform: translateY(50%);
  overflow-y: scroll;
  opacity: 0;
}

.modal-window__icon-button {
  @include indent-outer-right(24);
  @include indent-outer-top(24);
  position: absolute;
  right: 0;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.1s linear 0.25s;

  &:focus {
    @extend %typo-base-text-link-focused;
  }

  @include screen-small-down {
    height: 20px;
    width: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .icon {
    @include screen-small-down {
      height: 20px;
      width: 20px;
    }
  }
}

.modal-window__container {
  @include grid-container;
  @include indent-top(32);
  @include indent-bottom(80);
  position: relative;

  .comment-form__row {
    position: relative;
    z-index: 3;

    &:not(:last-child) {
      @include indent-bottom(24);
    }

    @include grid-medium-up(1 1) {
      display: grid;
      align-items: end;
    }

    .input-field:not(:last-child) {
      @include screen-small-down {
        margin-bottom: 16px;
      }
      @include screen-medium-up {
        padding-bottom: 0;
      }
    }

    .input-fields {
      display: flex;
      width: 100%;
      order: 1;
    }
  }

  .input-field__capcha {
    @include indent-outer-right(12);
    margin-top: 12px;
    height: 56px;
    border: 1px solid $color-text;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}