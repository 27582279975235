@use "node_modules/@make/ds2/scss/variables" as *;

.constructor-hint {
  position: fixed;
  left: 0;
  top: 0;
  padding: 4px 8px;
  background-color: $color-heading;
  font-size: 12px;
  line-height: 16px;
  color: $color-white;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease;
  z-index: 2;

  &.show {
    opacity: 1;
  }

  &:before {
    position: absolute;
    width: 6px;
    height: 6px;
    bottom: -3px;
    left: calc(50% - 3px);
    background-color: $color-heading;
    transform: rotate(-45deg);
    content: '';
  }
}
