////
/// Styles for the mobile menu component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";


.mobile-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 100vh;
  pointer-events: none;
  border-top: 1px solid $color-dividing-line-light;

  &:before {
    position: absolute;
    top: 0;
    left: -2px;
    right: -8px;
    bottom: 0;
    background-color: $color-white;
    opacity: 0;
    transition: opacity 0.1s linear;
    z-index: -1;
    content: "";
  }

  &.active {
    pointer-events: auto;

    .mobile-menu__wrapper {
      opacity: 1;
      transition-delay: 0.1s;
    }

    &:before {
      opacity: 1;
    }
  }

  @include screen-medium-up {
    display: none;
  }
}

.mobile-menu__wrapper {
  @extend %grid-container-base;
  @extend %helper-hidden-system-scrollbar;
  display: flex;
  height: 100%;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.1s linear;
  overflow-y: scroll;
}

.mobile-menu__section {
  position: relative;
  z-index: 1;
  padding-left: $grid-padding-tiny;
  padding-right: $grid-padding-tiny;
  margin-left: - $grid-padding-tiny;
  margin-right: - $grid-padding-tiny;
  transition: background-color 0.15s linear;

  @include screen-small-up {
    padding-left: $grid-padding-small;
    padding-right: $grid-padding-small;
    margin-left: - $grid-padding-small;
    margin-right: - $grid-padding-small;
  }

  &:not(.opened):last-child {
    .mobile-menu__section-text:after {
      display: none;
    }
  }

  .wave--active {
    background-color: $color-light-gray-fill;
  }

  &.opened {
    background-color: $color-light-gray-fill;

    .mobile-menu__section-text .icon {
      transform: rotate(180deg);
      fill: $color-text;
    }

    .wave--fade-out {
      opacity: 1;
    }
  }
}

.mobile-menu__section-content {
  > :first-child {
    @include indent-outer-top(20);
  }

  > :last-child {
    @include indent-outer-bottom(32);
  }
}

.mobile-menu__section-content,
.mobile-menu__subsection-content {
  transition: max-height 0.2s ease-out;
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
}

.mobile-menu__section-text {
  @include indent-vertical-pair(16);
  @include typo-make-sizes($typo-heading-h4-sizes, $isHeading: true);
  position: relative;
  font-weight: 600;

  &:after {
    position: absolute;
    left: - $grid-padding-tiny;
    right: - $grid-padding-tiny;
    bottom: 0;
    border-top: 1px solid $color-dividing-line-light;
    content: "";

    @include screen-small-up {
      left: - $grid-padding-small;
      right: - $grid-padding-small;
    }
  }

  .mobile-menu__section-text-item {
    text-align: left;
  }
}

.mobile-menu__subsection {
  .links-list {
    @include indent-left(16);
    @include indent-outer-bottom(24);
  }

  &.opened {
    .mobile-menu__subsection-text .icon {
      transform: rotate(180deg);
      fill: $color-hint;
    }
  }
}

.mobile-menu__subsection-text {
  @include indent-bottom(20);
  @include typo-make-sizes($typo-heading-h4-sizes, $isHeading: true);
  font-weight: 500;
  text-align: left;
}

.mobile-menu__section-text,
.mobile-menu__subsection-text {
  display: flex;
  align-items: center;
  width: 100%;

  .icon {
    margin-left: auto;
    transition: fill 0.1s linear, transform 0.1s linear;
    fill: $color-dividing-line;
  }

  &:hover {
    .icon {
      fill: $color-text;
    }
  }
}

.mobile-menu__subsection-text {
  .icon {
    fill: $color-field-frame;
  }

  &:hover {
    .icon {
      fill: $color-hint;
    }
  }
}

.mobile-menu__search {
  position: relative;

  &:after {
    position: absolute;
    left: - $grid-padding-tiny;
    right: - $grid-padding-tiny;
    bottom: 0;
    border-bottom: 1px solid $color-dividing-line-light;
    content: "";

    @include screen-small-up {
      left: - $grid-padding-small;
      right: - $grid-padding-small;
    }
  }

  .icon {
    position: absolute;
    top: 14px;
    right: 0;
    fill: $color-dividing-line;
  }
}

.mobile-menu__search-input {
  @include indent-vertical-pair(20);
  display: flex;
  border: none;
  border-radius: 0;
  width: 100%;
  padding-right: 24px;

  &:focus {
    + .mobile-menu__search-placeholder {
      opacity: 0;
    }

    + .mobile-menu__search-placeholder + .icon {
      fill: $color-text;
    }
  }
}

.mobile-menu__search-placeholder {
  @include indent-base(20, top);
  @include typo-make-sizes($typo-heading-h4-sizes, $isHeading: true);
  position: absolute;
  font-weight: 600;
  pointer-events: none;
  transition: opacity 0.1s linear;
  left: 0;
}
