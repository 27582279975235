@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@import "./variables/colors";


.medium-editor-toolbar {
  background: $color-heading;
  border-radius: 2px 2px 0 0;
  z-index: 10;
  display: none;
  visibility: hidden;
  //overflow: hidden;
}

.medium-editor-stalker-toolbar.medium-editor-toolbar-active {
  display: block;
  visibility: visible;
  position: absolute;
}

.medium-editor-toolbar .medium-editor-toolbar-actions {
  display: flex !important;
}

.medium-editor-toolbar .medium-editor-toolbar-actions .medium-editor-action {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  color: $color-gray;
  transition: background-color 0.1s linear;

  &.medium-editor-button-active {
    background-color: $color-interactive-element;
    color: $color-white;

    .medium-editor-custom-button__icon {
      fill: $color-white;
    }

    &:hover {
      background-color: $color-interactive-element-hover;
    }
  }

  &:before {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 2px;
    background-color: $color-interactive-element;
    content: '';
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  &:hover {
    background-color: $color-gray;
    color: $color-white;

    .medium-editor-custom-button__icon {
      fill: $color-white;
    }

    &:before {
      opacity: 1;
    }
  }

}

.medium-editor-toolbar .medium-editor-toolbar-form {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  background: $color-heading;
  border-radius: 2px;
  width: 320px;

  & > a {
    color: $color-white;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .medium-editor-toolbar-input {
    min-height: 37px;
    height: 37px;
    background: $color-heading;
    border: 1px solid $color-heading;
    @include indent-left(16);
    border-radius: 2px;
    box-shadow: none;
    display: inline-flex;
    width: 240px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.64;
  }

  .medium-editor-toolbar-save,
  .medium-editor-toolbar-close {
    display: inline-flex;
    width: 40px;
  }
}

.medium-editor-custom-button__icon {
  width: 24px;
  height: 24px;
  fill: $color-gray;
  transition: fill 0.1s linear;
}

.constructor-input-field__wrapper {
  b {
    font-weight: 700;
  }
}

.constructor-input-field__wrapper .admin-input input.medium-editor-toolbar-input:focus {
  box-shadow: 0 0 0 4px $color-interactive-element-outline;
}


.medium-editor__toolbar-wrapper {
  position: relative;
  margin: -12px -16px 0 -16px;

  .medium-editor-toolbar {
    position: static;
    visibility: visible;
    display: block;
  }
}

.medium-editor-toolbar-form-row{
  display: flex;
  align-items: center;
  border-top: 1px solid #f1f1f2;

  label{
    line-height: 48px;
    font-weight: 400;
    font-size: 14px;
    color: white;
    opacity: 0.35;
  }
}

.medium-editor-toolbar-anchor-target {
  appearance: auto !important;
  margin: 16px;
  margin-right: 8px;
  width: 16px !important;
  height: 16px !important;
  min-height: 16px !important;
}
