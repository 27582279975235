@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";

.constructor-settings__activity {
  align-items: center;
  @include indent-bottom(32px);
}

.constructor-settings__organization-name {
  @include indent-bottom(20);
}

.constructor-settings__organization-category {
  grid-column: 1;
}

.constructor-settings__wrapper {
  @include indent-bottom(48);
}

.constructor-settings__checkbox {
  display: inline-flex;
  border: 1px solid $color-dividing-line;
  padding: 12px;
  gap: 0;
  transition: background 0.15s ease;

  &.active {
    background: $color-white;
    border: none;
    border-radius: 2px;
  }
}

.constructor-settings__structure {
  height: 100%;
}


.constructor-settings__color-scheme-slider {
  width: 100%;
  grid-column: 1 / span 2;
}

.constructor-settings__logo-extensions {
  font-size: 16px;
  line-height: 24px;
  @include indent-bottom(8);
}

.constructor-settings__organization-fullname {
  @include indent-bottom(20);
}

.constructor-settings__organization-description {
  grid-column: 1;
}

.constructor-settings__organization-legal-address {
  @include indent-bottom(20);
}

.constructor-settings__organization-post-address {
  @include indent-bottom(32);
}

.constructor-settings__additional-wrapper {
  @include indent-bottom(48);

  .constructor-add-button {
    @include indent-outer-top(16);
  }
}

.constructor-settings__additional-input {
  position: relative;
}

.constructor-settings__additional-input-button {
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
}
