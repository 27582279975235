////
/// Custom styles for the "Site Menu" component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

$logotype-width-mobile: 32px;
$logotype-height-mobile: 32px;
$logotype-margin-mobile: 8px;

$logotype-width-tablet: 36px;
$logotype-height-tablet: 36px;
$logotype-margin-tablet: 12px;

$logotype-width-desktop: 40px;
$logotype-height-desktop: 40px;
$logotype-margin-desktop: 24px;

$logotype-offset-breakpoint: 1540px;
$link-item-custom-breakpoint-1: 960px;
$link-item-custom-breakpoint-2: 880px;
$link-item-custom-breakpoint-3: 780px;


.site-menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
  z-index: 3;

  .site-menu__link {
    &:focus {
      outline-color: var(--color-interactive-element-rgba);
    }

    &.active {
      color: var(--color-interactive-element-active);

      &:before {
        bottom: 0;
        background-color: var(--color-interactive-element-active);
      }
    }
  }

  &.disabled {
    .site-menu__link {
      color: $color-hint;
      pointer-events: none;
    }
  }

  &.site-menu--sticky {
    z-index: 3;

    &.disabled {
      z-index: 4;
    }
  }

  .site-menu__logotype {
    &:focus {
      .site-menu__logotype-text {
        outline-color: var(--color-interactive-element-rgba);
      }
    }
  }
}

.site-menu__logotype-symbol-mobile {
  width: $logotype-width-mobile;
  height: $logotype-height-mobile;
  margin-right: $logotype-margin-mobile;
  background-color: $color-dividing-line-light;
  flex-shrink: 0;

  @include screen-medium-up {
    display: none;
  }
}

.site-menu__logotype-symbol-desktop {
  display: none;
  justify-content: center;
  flex-shrink: 0;

  &[role="img"] {
    pointer-events: auto;
  }

  @include screen-medium-up {
    display: flex;
    width: $logotype-width-tablet;
    height: $logotype-height-tablet;
    margin-right: $logotype-margin-tablet;
  }

  @include screen-large-up {
    width: $logotype-width-desktop;
    height: $logotype-height-desktop;
    margin-right: $logotype-margin-desktop;
  }

  @include screen-min($logotype-offset-breakpoint) {
    // position: absolute;
    // top: 0;
    // right: 100%;
  }
}

.site-menu__logotype-wrapper {
  padding-top: 32px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;

  @include screen-small-down {
    flex-direction: column;
  }
}

.site-menu__logotype {
  position: relative;
  min-height: $logotype-height-mobile;

  @include screen-medium-up {
    min-height: $logotype-height-tablet;
  }

  @include screen-large-up {
    min-height: $logotype-height-desktop;
  }
}

.site-menu__logotype-text {
  display: block !important;
}

.site-menu__logotype-text-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #00001E;
  margin-bottom: 8px;
}

.site-menu__logotype-text-full-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #71717C;
}

.site-menu__link-item {
  margin-right: 32px;

  .site-menu__link {
    padding: 24px 0;
  }

  &:last-child {
    @include screen-large-down {
      display: none;
    }
  }

  &:nth-last-child(2) {
    @include screen-max($link-item-custom-breakpoint-1) {
      display: none;
    }
  }

  &:nth-last-child(3) {
    @include screen-max($link-item-custom-breakpoint-2) {
      display: none;
    }
  }

  &:nth-last-child(4) {
    @include screen-max($link-item-custom-breakpoint-3) {
      display: none;
    }
  }
}

// TODO: WIP styles
.site-menu__icon-button {
  // 24px — icon width:
  $offset-mobile: ($logotype-height-mobile - 24px) * 0.5;
  $offset-tablet: ($logotype-height-tablet - 24px) * 0.5;
  $offset-desktop: ($logotype-height-desktop - 24px) * 0.5;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;
  margin-left: - $offset-mobile;
  margin-right: - $offset-mobile;

  @include screen-medium-up {
    padding-left: $offset-tablet;
    padding-right: $offset-tablet;
    margin-left: - $offset-tablet;
    margin-right: - $offset-tablet;
  }
  @include screen-large-up {
    padding-left: $offset-desktop;
    padding-right: $offset-desktop;
    margin-left: - $offset-desktop;
    margin-right: - $offset-desktop;
  }

}

.site-menu__search-button,
.site-menu__visually-impaired {
  margin-left: auto;
}

.site-menu__visually-impaired {
  .site-menu__link {
    margin-left: 0;
    margin-right: 8px;
  }
}

.site-menu__site-map-button,
.site-menu__search-button {
  @include screen-small-down {
    display: none !important;
  }
}

.site-menu__visually-impaired {
  .site-menu__link {
    @include indent-outer-left(8);
  }
}

.site-menu__site-map-button {
  .icon {
    transition: opacity 0.15s linear;
  }

  .icon--active {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
    opacity: 0;
    transition-duration: 0s;
  }

  &.active {
    .icon--default {
      opacity: 0;
      transition-duration: 0s;
    }

    .icon--active {
      opacity: 1;
      transition-duration: 0.15s;
    }
  }
}

.site-menu__site-map-button--search {
  margin-left: 8px;
}

.site-menu__hamburger-button {
  display: inline-flex;
  margin-left: auto;

  .icon--active {
    display: none;
  }

  &.active {
    .icon--default {
      display: none;
    }

    .icon--active {
      display: block;
    }
  }
}

.site-menu__links-wrapper {
  margin-left: 0 !important;

  .site-menu__hamburger-button {
    margin-bottom: 16px;
    @include screen-medium-up {
      display: none;
    }
  }

  .site-menu__site-map-button.site-menu__icon-button:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}

.site-submenu__wrapper > .site-submenu__column {
  > :not(:first-child) {
    padding-top: 0;
  }

  > :not(:last-child) {
    @include indent-bottom(12);
  }

  .site-subsubmenu__link:focus:not(:hover) .site-subsubmenu__link-text {
    outline-color: var(--color-interactive-element-rgba);
  }
}
