////
/// Custom styles for the site-subsubmenu component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/site-subsubmenu";


.site-subsubmenu__link {
  text-align: left;

  .icon {
    transform: rotate(-90deg);
  }
}
