////
/// The anchor menu component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";
@use "~@make/ds2/scss/typography";

.anchor-menu {
  display: none;
  position: relative;
  @include indent-outer-top(16);
  @include indent-base(80, top);

  justify-self: center;
  border-left: 1px solid $color-dividing-line;

  @include screen-large-up {
    grid-column: 5 / span 2;
    display: block;
  }
}

.anchor-menu--sticky {
  position: sticky;
  align-self: start;
}

.anchor-menu__indicator {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--color-interactive-element);
  width: 2px;
  transition: transform 0.15s ease;
  transform-origin: 0 0;
  content: "";
}

.anchor-menu__item {
  position: relative;
  padding-left: 24px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.active {
    .spoiler__button {
      font-weight: 700;
      color: $color-heading;
    }
  }

  .anchor-menu__item {
    @include indent-inner-left(16);

    .anchor-menu__link {
      &.active {
        font-weight: 400;
        color: var(--color-interactive-element);
      }
    }

  }

  .spoiler__button {
    line-height: inherit;
    color: inherit;
    margin-top: 0;
  }

  &.opened {
    .anchor-menu__second-level {
      margin-top: 12px;
    }
  }
}

.anchor-menu__link {
  @extend %typo-base-text-link-transition;
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  color: $color-text;

  &.active {
    color: $color-text;
    font-weight: 500;
  }
}

.anchor-menu__level-2 {
  transition: margin-top 0.25s ease-out;
}

.anchor-menu__level-3 {
  transition: margin-top 0.25s ease-out;

  & > .anchor-menu__item {
    padding-left: 0;
  }

  & .anchor-menu__link {
    @include typo-make-sizes($typo-paragraph-small-sizes);
  }
}

.anchor-menu__spoiler-button-wrapper {
  display: flex;
  align-items: center;
  gap: 0;
  width: 100%;
  justify-content: space-between;

  & > button:focus {
    outline: none;
  }

  & > .anchor-menu-spoiler__button {
    display: inline-flex;

    svg {
      transition: transform 0.1s;
    }
  }
}

.anchor-menu__item.spoiler {
  overflow: hidden;

  &.opened > .anchor-menu__spoiler-button-wrapper {
    & > .anchor-menu-spoiler__button {
      display: inline-flex;

      & > svg {
        transform: rotate(180deg);
      }
    }
  }
}

.anchor-menu__spoiler-content {
  height: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s;

  & > .anchor-menu__item:first-child {
    @include indent-top(12);
  }
}
