////
/// Styles for the government structure list component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/text-field";
@use "~@make/ds2/scss/components/button";
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";
@use "~@make/ds2/scss/components/spoiler";

.page-government {
  .article__content {
    grid-column: 1/span 4;
  }

  .constructor-contact-person {
    .constructor-contact-person-item__name-link {
      display: block;
      color: currentColor;
      border: none;

      &:before {
        display: none;
      }

      &:hover {
        color: var(--color-interactive-element-hover);
      }

      &:active {
        color: var(--color-interactive-element-active);
      }

      &:focus {
        outline-color: var(--color-interactive-element-rgba);
      }
    }
  }

  .content-block--with-border-top {
    border-top: 1px solid $color-dividing-line-light;
  }

  .constructor-contact-person-item {

    &:first-child {
      @include indent-top(32);
    }

    &.main-item {
      @include indent-bottom(32);
    }

    &:not(:last-child) {
      @include indent-top(24);
      @include indent-bottom(24);
      margin-bottom: 0;
    }

    &:last-child {
      @include indent-top(24);

      .accordion {
        &:before {
          @include indent-outer-bottom(-32);
        }
      }
    }
  }
}

.government-structure {
  .person__subordinates {
    visibility: hidden;
    position: absolute;
    transition: opacity 0.3s linear 0.05s, transform 0.3s ease-out 0.05s;
    opacity: 0;

    @include screen-medium-only {
      position: static;
      visibility: visible;
      opacity: 1;
      grid-column: 2 / span 3;
    }

    @include screen-large-up {
      transform: translateY(64px);
    }

    .person-info__photo-wrapper {
      @include screen-medium-only {
        width: 40px;
        height: 40px;
        background-color: $color-dividing-line;
      }
    }

    .person-info__position {
      @include screen-medium-down {
        display: none;
      }
    }
  }

  .person.hidden,
  .person.fadeOut {
    pointer-events: none;
    opacity: 0;
  }

  .person.placeholder {
    pointer-events: none;

    .person__photo-wrapper:before {
      opacity: 1;
    }

    .person__name,
    .person__position,
    .government-structure__expand-wrapper {
      visibility: hidden;
    }
  }
}

.government-structure__department-link-wrapper {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    @include indent-outer-top(24);
  }

  + .person__subordinate {
    @include indent-outer-top(20);
    @include indent-top(20);
    border-top: 1px solid $color-dividing-line-light;
  }
}

.government-structure__department-link {
  @extend %typo-base-text-link;
  @extend %typo-text-link-external-url-arrow;
  @include typo-make-sizes($typo-paragraph-nav-sizes);

  &[href^="http"]:after {
    position: static;
    display: inline-flex;
  }

  @include screen-medium-only {
    font-size: 14px;
    line-height: typo-text-line-height(14px);
  }
}

