////
/// Styles for the site map modal window component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
/// @todo major refactor
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/site-menu";


.site-map-modal {
  @include screen-small-down {
    display: none;
  }
}

.site-map {
  .links-list {
    @include typo-make-sizes($typo-paragraph-nav-sizes);
    @include indent-top(16);
    @include indent-bottom(40);
  }

  .site-submenu {
    position: relative;
    background: transparent;

    &:before, &:after {
      display: none;
    }

    &.opened{
      z-index: 3;
    }
  }

  .links-list__link, .site-subsubmenu__link {
    font-size: 24px;
    line-height: 28px;
  }

  .site-submenu__wrapper > .site-submenu__column > :not(:last-child) {
    padding-bottom: 24px;
  }

  .site-subsubmenu-container {
    .links-list__link {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .site-subsubmenu-container {
    .links-list__item {
      padding-bottom: 12px;
    }

    .links-list__item--with-child {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .site-submenu__column:nth-child(2) {
    grid-column: 3/span 4;

    .links-list {
      column-count: 2;
      grid-column-gap: 32px;
    }

  }
}

.site-map__section {
  @include screen-medium-up {
    grid-column: span 4;
  }
  @include screen-large-up {
    grid-column: span 3;
  }
}

.site-map__section-link {
  @extend %typo-text-link-menu;
  @include typo-make-sizes($typo-heading-h4-sizes, $isHeading: true);
  font-weight: 600;
}
