////
/// Styles for the news component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "sass:math";
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";


// The initial zoom of the image illustration:
$illustration-zoom: 12.5% !default;

// The illustration default height on mobile phones:
$illustration-height-mobile: 140px !default;

// The illustration default height on desktop screens:
$illustration-height-desktop: 236px !default;


.news {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: border-color 0.1s linear;
  gap: 0;

  &:hover {
    .news__title {
      color: var(--color-interactive-element-hover);
    }

    .news__illustration-image {
      transition-duration: 2s;
      transform: scale(1);
    }

    .news__admin {
      opacity: 1;
    }
  }

  &:active {
    .news__title {
      color: $color-interactive-element-active;
    }
  }
}

.news--unpublished {
  .news__title,
  .news__description,
  .news__illustration {
    opacity: 0.48;
  }
}

$--color-interactive-element-hover: var(--color-interactive-element-hover);
.news__admin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 0;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: rgba($--color-interactive-element-hover, 0.04);
  border-radius: 2px;
  border: 2px solid var(--color-interactive-element-hover);
  transition: opacity 0.1s linear;
  opacity: 0;
  z-index: 2;
}

.news__admin-container-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  //noinspection CssInvalidFunction
  padding-top: percentage(math.div(9, 16));
}

.news__admin-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news__title {
  @extend %typo-base-text-link-transition;
  @include typo-make-sizes($typo-paragraph-text-sizes);
  font-weight: 400;
  outline-color: transparent;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
  }
}

.news__link {
  order: 2;

  &:focus {
    .news__title {
      @extend %typo-base-text-link-focused;
    }
  }
}

.news__publication-date {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
  display: inline-flex;

  &:after {
    display: none !important;
  }
}

.news__publication-date + .news__topic-link {
  margin-left: 8px;
}

.news__publication-info {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  @include indent-bottom(12);
  display: flex;
  order: 1;
  color: $color-hint;
  align-items: center;

  .news__publication-date:not(:only-child) {
    &:after {
      @extend %typo-text-bullet;
      padding-left: 8px;
    }
  }

  .news__topic-link {
    @extend %typo-base-text-link;
    @extend %typo-base-text-link-underline;
    z-index: 2;

    &:hover {
      @extend %typo-base-text-link-underlined;
    }
  }

  .news__publication-play-icon {
    width: 24px;
    height: 24px;
    fill: #2B2B3C;
    margin-left: 12px;
  }
}

.news__publication-detail {
  @include indent-outer-top(16);
  @include typo-make-sizes($typo-paragraph-small-sizes);
  order: 2;
  color: $color-hint;
  display: flex;
  align-items: center;

  & + .news__publication-detail {
    @include indent-outer-top(12);
  }

  .icon {
    @include indent-outer-right(8);
    width: 20px;
    height: 20px;
    fill: $color-text;
  }
}

.news__illustration {
  @include indent-outer-bottom(16);
  position: relative;
  display: flex;
  border-radius: 2px;
  overflow: hidden;
  order: 0;
  height: $illustration-height-mobile;
  background-color: $color-dividing-line-light;

  @include screen-medium-up {
    height: $illustration-height-desktop;
  }

  .news__illustration-icon-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .news__publication-play-icon {
    width: 32px;
    height: 32px;
    fill: white;
  }

}

.news__illustration-image,
.news__illustration-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.news__illustration-image {
  position: relative;
  top: - $illustration-zoom * 0.5;
  left: - $illustration-zoom * 0.5;
  min-width: 100% + $illustration-zoom;
  min-height: 100% + $illustration-zoom;
  //noinspection CssInvalidFunction
  transform: scale(#{math.div(100.25%, (100% + $illustration-zoom))});
  transform-origin: 50% 50%;
  transition: transform 0.3s ease-out;
  // Fix for the "jumping" issue in Chrome:
  will-change: transform;
}

.article__image-wrapper > .news__illustration-image {
  top: - $illustration-zoom * 0.5;
  left: - $illustration-zoom * 0.5;
}
