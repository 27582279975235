@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";

.constructor-news__cards {
  @include indent-outer-bottom(24);
}

.constructor-news__card:not(:last-child) {
  @include indent-outer-bottom(24);
  border-bottom: 1px solid $color-dividing-line;
}

.constructor-news__activity {
  align-items: center;
  @include indent-outer-bottom(32);
}

.constructor-news__publication-date {
  @include indent-outer-bottom(12);
}

.constructor-news__file-uploader-description {
  font-size: 16px;
  line-height: 24px;
}

.constructor-news__file-uploader-description--small {
  font-size: 14px;
  line-height: 20px;
  color: $color-hint;
  @include indent-outer-bottom(8);
}

.constructor-news__file-list {
  @include indent-outer-bottom(32);
}

.constructor-news__announce-text {
  @include indent-top(20);
}

.constructor-news__publication-text {
  @include indent-outer-bottom(48);
}
