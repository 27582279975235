@use "~@make/ds2/scss/variables" as *;

.flexible-modal {
  position: absolute;
  border: none !important;
  background: white;
  overflow-y: hidden;
  z-index: 51!important;
}

.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}

.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}

.flexible-modal-drag-area {
  background: #D9D9DC !important;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

.warning-modal-message__block{
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
}

.warning-modal-message{
  font-size: 24px;
  color: red;
  text-align: center;
}

.warning-modal-message__buttons{
  display: flex;
  justify-content: space-around;
}

.settings-page__modal{
  .flexible-modal-drag-area {
    background: rgba(0, 97, 217, 0.5) !important;
  }

  .file-manager__top-block {
    .constructor-top-bar-button {
      background: transparent !important;
    }
  }
}