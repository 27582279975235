////
/// Styles for the slider in article page.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";
@use "~@make/ds2/scss/typography";


.article-slider__wrapper {
  // @include indent-outer-top(-112);
  margin-top: -48px;
  @include indent-inner-bottom(32);
  width: 100%;
  overflow: hidden;
  position: relative;

  @include screen-medium-up {
    @include indent-outer-top(-112);
  }

  &:hover {
    .article-slider__button--prev,
    .article-slider__button--next {
      @include screen-medium-up {
        pointer-events: all;
        opacity: 1;
        z-index: 2;
      }
    }
  }
}

.article__slider {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .swiper-slide-prev, .swiper-slide-next,
  .swiper-slide-duplicate-next, .swiper-slide-duplicate-prev {
    opacity: 0.32;
    transition: opacity 0.25s linear;

    &:hover {
      opacity: 1;
    }
  }

  .image-caption {
    @include typo-make-sizes($typo-paragraph-small-sizes);
    @include indent-outer-top(16);
    color: $color-hint;

    @include screen-small-down {
      @include indent-horizontal-pair(32);
    }

    &:not(:last-child) {
      @include indent-outer-bottom(64);
    }
  }
}

.article-slider__list {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  transition-property: transform;
  box-sizing: content-box;
}

.article-slide {
  flex-shrink: 0;
  position: relative;
  transition: opacity 0.1s linear;
  height: 100%;
  background-color: $color-dividing-line-light;

  &.article-slide--vertical {
    .article-slide__illustration img {
      width: auto;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.swiper-slide-active {
  &:hover {
    cursor: pointer;

    .article-slide__open-controller {
      opacity: 1;
      pointer-events: all;
      z-index: 1;
    }
  }
}

.article-slide__illustration {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}

.article-slide__illustration-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  pointer-events: none;
}

.article-slider__button--prev,
.article-slider__button--next {
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: calc(50% - 24px);
  box-shadow: 0 2px 4px rgba(0, 0, 30, 0.08);
  transition: box-shadow 0.1s linear, opacity 0.25s linear, transform 0.2s ease-out;
  opacity: 0;
  pointer-events: none;

  @include screen-medium-down {
    top: calc(50% - 48px);
  }

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 30, 0.24);
    z-index: 2;

    .icon {
      fill: $color-heading;
    }
  }

  &.swiper-button-disabled {
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .icon {
    fill: $color-text;
    transition: fill 0.1s linear;
  }
}

.article-slider__button--prev {
  @include indent-outer-left(40);
  left: 0;
}

.article-slider__button--next {
  @include indent-outer-right(40);
  right: 0;
}

.article-slider__pagination-wrapper {
  @include grid-container;
  @include grid-standard;
  display: grid;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  top: 0;
  pointer-events: none;

  @include screen-medium-down {
    display: flex;
  }
}

.article-slider__pagination-bullet-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  z-index: 1;
  grid-gap: 8px;
}

.article-slider__pagination-bullet-wrapper--half {
  right: calc(50% + 26px);
}

.article-slider__pagination-bullet {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.article-slider__pagination {
  @include typo-make-sizes($typo-paragraph-text-sizes);
  color: white;
  grid-column: 2 / span 2;

  @include screen-small-down {
    padding: 16px;
  }

  @include screen-small-up {
    @include indent-vertical-pair(16);
  }

  @include screen-medium-only {
    @include indent-horizontal-pair(16);
  }

  @include screen-large-up {
    @include indent-horizontal-pair(32);
    @include indent-vertical-pair(32);
  }
}

.article-slide__open-controller {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  background-color: rgba($color: $color-heading, $alpha: 0.16);
  transition: opacity 0.1s linear;

  @include screen-small-down {
    align-items: flex-end;
    justify-content: flex-start;
    padding: 16px;
  }

  .icon {
    fill: white;
    width: 40px;
    height: 40px;

    @include screen-small-down {
      width: 28px;
      height: 28px;
    }
  }
}

.article-slider__button-wrapper {
  @include indent-outer-top(48);
  @include indent-outer-bottom(-16);
  width: 100%;
  display: none;

  .button {
    width: 100%;

    &.hidden {
      display: none;
    }
  }
}

.swiper-pagination-bullet {
  width: 32px;
  height: 4px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-bottom: 2px;
}

.swiper-pagination-bullet-active {
  background: #0061D9;
}