@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use '../variables/colors' as *;
@use "~@make/ds2/scss/placeholders";

.page__container {
  display: grid;
  margin-left: 48px;
  background-color: $color-cover;
  min-width: 100vw;
  min-height: 100vh;
  padding-left: 240px;

  @media (min-width: 1088px) {
    grid-template-columns: 10fr 2fr;
  }

  @media (min-width: 1680px) {
    grid-template-columns: 8fr 4fr;
  }
}

.content {
  @include indent-top(48);
  @include indent-left(48);
}

section {
  @include indent-bottom(80);
}

.page__header {
  display: flex;
  align-items: center;
  @include indent-outer-bottom(32);
}

.page__header-wrapper {
  flex-grow: 1;
}

.page__heading {
  font-size: 44px;
  line-height: 56px;
  flex-grow: 1;
}

.page__subsection {
  @include indent-outer-bottom(48);
}

.page__section-title-wrapper {
  @include indent-outer-bottom(42);

  .page__section-title {
    @include indent-bottom(16);
  }
}

.page__section-title {
  font-size: 36px;
  line-height: 44px;
  @include indent-bottom(32);
}

.page__section-subtitle {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  @include indent-bottom(24);
}

.page__block-subtitle {
  @include indent-bottom(16);
}

.page__section-description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  @include indent-top(12);
  color: $color-hint;
}

.page__section-description--link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.page__save-button {
  position: sticky;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  z-index: 4;
  pointer-events: none;

  .constructor-button {
    pointer-events: all;
  }
}

.page__content-form {
  @include indent-outer-bottom(32);
}

.page__content-inputs-row {
  @include indent-outer-bottom(20);
}

.page__content-2-columns {
  display: grid;
  @include grid-medium-up(1 1);
  gap: 20px;
}

.page__content-3-columns {
  display: grid;
  @include grid-medium-up(1 1 1);
  gap: 12px 20px;
}

*[contenteditable='true'] {
  outline: none;
}

.page__file-uploader-description {
  @include typo-make-sizes($typo-paragraph-nav-sizes)
}

.page__file-uploader-description--small {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
  @include indent-outer-bottom(8);
}

.page__tabs {
  border-bottom: 1px solid $color-dividing-line;

  .MuiTabs-root,
  .MuiTabs-fixed {
    overflow: unset !important;
  }

  .MuiTab-root {
    min-width: auto;
  }

  .MuiButtonBase-root {
    justify-content: flex-start;
    padding: 0;
    text-transform: none;
    @include indent-right(4);
    @include indent-outer-right(24);
  }

  .MuiTab-wrapper {
    display: inline;
  }

  .MuiTabs-indicator {
    bottom: -1px;
    background-color: $color-interactive-element;
  }

  .MuiTab-textColorInherit {
    opacity: 1;
    transition: color 0.15s ease;
  }

  .MuiTab-textColorInherit.Mui-selected {
    color: $color-interactive-element;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  &:not(.admin-tabs) {
    .MuiTabs-indicator {
      background-color: var(--color-interactive-element);
    }

    .MuiTab-textColorInherit.Mui-selected {
      color: var(--color-interactive-element);
    }

  }
}

i {
  font-style: italic;
}
