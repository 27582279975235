////
/// Styles for the person card component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";


.person,
.person__photo-wrapper {
  display: flex;
}

.person__link {
  &:hover {
    .person__name {
      color: var(--color-interactive-element-hover);
    }
  }

  &:active {
    .person__name {
      color: $color-interactive-element-active;
    }
  }

  &:focus {
    .person__name {
      @extend %typo-base-text-link-focused;
    }
  }
}

.person--card {
  flex-direction: column;

  .person__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .person__name {
    @extend %typo-base-text-link-transition;
    @include indent-outer-top(24);
    @include typo-make-sizes($typo-heading-h3-sizes, $isHeading: true);
    outline-color: transparent;
  }

  .person__surname {
    display: flex;
    font-weight: 600;
  }

  .person__position {
    @include typo-make-sizes($typo-paragraph-small-sizes);
    padding-top: 8px;
    color: $color-hint;
  }
}

.person--detail-card {
  @include grid-standard;
  display: grid;

  .person__photo-wrapper {
    position: relative;
    display: flex;
    grid-column: span 2;
    height: 0;
    padding-top: 100%;

    &[href]:hover {
      + .person__info-wrapper .person__name {
        color: var(--color-interactive-element-hover);

        .person__name-patronymic {
          @extend %typo-text-link-arrow-hover;
        }
      }
    }
  }

  .person__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .person__info-wrapper {
    display: flex;
    flex-direction: column;
    grid-column: span 2;

    @include screen-small-down {
      margin-top: 24px;
    }

    @include screen-large-up {
      grid-column: span 4;
    }
  }

  .person__name {
    @extend %typo-base-text-link-transition;
    @include typo-make-sizes($typo-heading-h2-sizes, $isHeading: true);
    display: flex;
    position: relative;
    outline-color: transparent;
    flex-direction: column;

    &[href] {
      &:hover {
        color: var(--color-interactive-element-hover);

        .person__name-patronymic {
          @extend %typo-text-link-arrow-hover;
        }
      }

      &:active {
        color: $color-interactive-element-active;
      }

      &:focus {
        @extend %typo-base-text-link-focused;
      }

      .person__name-patronymic {
        @extend %typo-text-link-arrow;

        &:after {
          position: static;
          display: inline-flex;
          align-self: flex-start;
        }
      }
    }
  }

  .person__name-patronymic {
    display: inline-flex;
    align-self: flex-start;
    gap: 0;
  }

  .person__surname {
    display: inline-flex;
    font-weight: 600;
  }

  .person__position {
    @include indent-top(16);
  }

  .person__contacts {
    @include indent-top(40);
    @include typo-make-sizes($typo-paragraph-nav-sizes);
    @include screen-large-up {
      margin-top: auto;
    }
  }

  .person__contacts-list {
    @include indent-top(24);
    @include screen-small-only {
      @include grid-template(1 1);
      display: grid;
    }
    @include grid-large-up(1 1) {
      display: grid;
    }
    @include grid-huge-up(1 1 1 1);
  }

  .person__contacts-list-item {
    padding-bottom: 16px;

    @include screen-medium-up {
      padding-bottom: 24px;
    }

    @include screen-huge-up {
      padding-bottom: 0;
    }

    > :not(:last-child) {
      @include indent-outer-bottom(24);
    }

    //& .links-list__link {
    //  color: var(--color-interactive-element);
    //}
  }

  .person__email-wrapper {
    display: flex;
  }

  .person__email {
    @extend %typo-base-text-link;

    color: var(--color-interactive-element);

    &:hover {
      color: var(--color-interactive-element-hover);
    }

    &:active {
      color: var(--color-interactive-element-active);
    }

    &:focus {
      outline-color: var(--color-interactive-element-rgba);
    }
  }

  .person__contacts__description {
    @include indent-top(24);
    @include typo-make-sizes($typo-paragraph-small-sizes);
    color: $color-hint;
  }
}

.person__tabs-wrapper {
  @include indent-bottom(72);
}

.person__tab-subordinates {
  @include grid-standard;
  display: grid;

  .person__subordinate {
    grid-column: span 2;

    @include screen-small-down {
      &:not(:last-child) {
        border-bottom: 1px solid $color-dividing-line-light;
        @include indent-outer-bottom(24);
      }
    }

    @include screen-large-up {
      grid-column: span 3;
    }

    .government-structure__department-link-wrapper {
      .government-structure__department-link {
        @include indent-bottom(20);
        border-bottom: 1px solid $color-dividing-line-light;

        &:not(:first-child) {
          @include indent-outer-top(20);
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

  }
}

.government-structure__department-link {
  color: var(--color-interactive-element);

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}

.person__biography {
  @include grid-standard;
  display: grid;
  @include indent-top(40);
  @include indent-bottom(72);
  border-top: 1px solid $color-dividing-line;

  & > .content-block {
    grid-column: span 2;

    @include screen-medium-up {
      grid-column: span 4;
    }
  }

  & h2 {
    @include indent-bottom(24);
  }
}
