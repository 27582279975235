////
/// Styles for the block-link component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/text-field";
@use "~@make/ds2/scss/components/button";
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";


.block-link {
  @extend %typo-text-link-external-url-arrow;
  @include indent-vertical-pair(16);
  @include indent-horizontal-pair(24);
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 2px;
  transition: box-shadow 0.1s linear;
  box-shadow: 0 16px 32px transparent;
  text-align: left;
  z-index: 1;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid $color-dividing-line;
    border-radius: inherit;
    z-index: -1;
    content: "";
  }

  &[href^="http"]:after {
    @include indent-base(16, top right);
    left: auto;
  }

  &:hover {
    box-shadow: 0 16px 32px rgba($color-heading, 0.04);

    .block-link__title {
      color: var(--color-interactive-element-hover);
    }
  }

  &:focus:not(:hover) {
    box-shadow: 0 0 0 4px $color-interactive-element-outline;
  }

  .wave--hover {
    background-color: $color-white;
    transition-duration: 0.25s;
  }
}

.block-link--important {
  &:before {
    background-color: $color-white;
    border: none;
  }
}

.block-link__title {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  transition: color 0.1s linear;
}

.block-link__description {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
}
