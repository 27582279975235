////
/// The "Documents section" component.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";

.documents-page {
  .site-search-wrapper {
    @include indent-outer-top(-32);
    @include indent-outer-horizontal-pair(-40);

    @include screen-small-down {
      margin-left: - $grid-padding-small;
      margin-right: - $grid-padding-small;
    }
    @include screen-tiny-only {
      margin-left: - $grid-padding-tiny;
      margin-right: - $grid-padding-tiny;
    }

    @include screen-medium-only {
      margin-left: - $grid-padding-medium;
      margin-right: - $grid-padding-medium;
    }

    @include screen-large-up {
      margin-left: - $grid-padding-large;
      margin-right: - $grid-padding-large;
    }

    @include screen-huge-up {
      margin-left: - $grid-padding-huge;
      margin-right: - $grid-padding-huge;
    }

    .input-row {
      position: relative;
    }

    .site-search--advanced {
      @include screen-small-down {
        margin-bottom: 0;
      }
    }

    .advanced-search__spoiler-button {
      margin-bottom: 32px;
    }

    &.opened .site-search--advanced {
      @include screen-medium-down {
        margin-bottom: 0;
      }

      .litepicker:before {
        content: none;
      }
    }

    .advanced-search__spoiler-button .spoiler__button {
      @include screen-small-down {
        margin-top: -4px !important;
      }
    }
  }

  .tabs__buttons-scroll-wrapper {
    & + .site-search-wrapper {
      margin-top: 0;

      .site-search__input-wrapper {
        @include screen-large-up {
          margin-bottom: 16px;
        }
      }

      &:not(.opened) + .documents__filters-wrapper {
        @include screen-large-up {
          margin-top: 12px;
        }
      }

      &.opened {
        .site-search--advanced {
          @include indent-outer-top(-40);
          max-height: none !important;
        }
      }
    }
  }
}

.document-page {
  .article__controllers-wrapper {
    @include indent-inner-bottom(80);
    border-top: 1px solid $color-dividing-line;
    border-bottom: none;

    @include screen-medium-down {
      @include indent-inner-top(32);
    }
  }
}

.documents__list {
  @include screen-medium-up {
    @include indent-inner-bottom(32);
    @include indent-outer-bottom(-80);
  }

  .document-item:last-child {
    border-color: transparent;
  }

  & + .documents__filters-wrapper {
    @include indent-inner-top(80);
    @include indent-outer-top(16);

    @include screen-small-down {
      display: flex;
      flex-direction: column-reverse;
    }

    .documents__filter {
      @include screen-small-down {
        width: 100%;
        justify-content: end;
        margin-bottom: 16px;
      }
    }
  }
}

.document-item {
  @include indent-inner-vertical-pair(32);
  position: relative;
  display: grid;
  align-items: start;
  border-bottom: 1px solid $color-dividing-line-light;
  transition: border-color 0.1s linear;

  @include screen-small-down {
    display: flex;
    flex-direction: column;
  }

  @include screen-medium-only {
    @include grid-template(1 1 1 1 1 1 1 1);
    column-gap: $grid-gutter-medium;

  }

  @include screen-large-up {
    @include grid-template(1 3 2);
    column-gap: $grid-gutter-large;
  }

  @include screen-huge-up {
    column-gap: $grid-gutter-huge;
  }

  &:hover {
    border-color: $color-dividing-line;
  }
}

.document-item__info {
  gap: 0;
  display: flex;
  flex-direction: column;

  @include screen-medium-down {
    flex-direction: row;
  }

  @include screen-medium-only {
    grid-column: 1 / span 6;
  }
}

.document-item__number {
  @include indent-outer-bottom(12);
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-text;

  @include screen-medium-down {
    display: flex;
  }

  &:not(:only-child) {
    &:after {
      @extend %typo-text-bullet;
      @include screen-medium-down {
        padding-left: 8px;
        padding-right: 8px;
      }

      @include screen-large-up {
        content: none;
      }
    }
  }
}

.document-item__detail {
  @include screen-medium-only {
    grid-column: 1 / span 6;
  }
}

.document-item__number-link {
  @include screen-medium-down {
    margin-right: 4px;
  }
}

.document-item__number-title {
  color: $color-text;
}

.document-item__number-link, .document-item__detail-link {
  display: inline;
  @extend %typo-base-text-link;
  @extend %typo-base-text-link-underline;
  z-index: 1;

  &:hover {
    @extend %typo-base-text-link-underlined;
  }
}

.document-item__detail-items {
  @include indent-outer-top(16);
  @include indent-outer-bottom(-8);
  display: flex;
  flex-wrap: wrap;
  gap: 0;

  @include screen-medium-only {
    grid-column: 1 / span 6;
  }

  @include screen-large-up {
    grid-column: 2 / span 1;
  }

  .document-item__detail-item {
    @include indent-outer-bottom(8);
    @include typo-make-sizes($typo-paragraph-small-sizes);
    display: inline-flex;
    gap: 0;

    &:after {
      @extend %typo-text-bullet;
      padding-left: 8px;
      padding-right: 8px;
    }

    &:only-child,
    &:last-child,
    &.last-in-row {
      &::after {
        content: none;
      }
    }
  }
}

.document-item__detail-item.compensation:after {
  opacity: 0;
}

.document-item__detail-title {
  color: $color-hint;
}

.document-item__date {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  order: 2;
  color: $color-hint;
  transition: color 0.1s linear;

  @include screen-medium-down {
    @include indent-outer-bottom(12);
  }
}

.document-item__link {
  @include screen-medium-only {
    grid-column: 1 / span 6;
  }

  &:hover {
    .document-item__title {
      color: $color-interactive-element-hover;
    }
  }
}

.document-item__title {
  @include typo-make-sizes($typo-heading-h4-sizes, $isHeading: true);
  font-weight: 400;
  transition: color 0.1s linear;
}

.document-item__controllers {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include screen-small-down {
    @include grid-template(1 1);
    display: grid;
    margin-top: 24px;
    order: 1;
  }


  @include screen-medium-only {
    grid-column: 7/span 2;
    grid-row: 1/span 2;
    justify-content: flex-end;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  @include screen-large-up {
    @include grid-template(1 1 1);
    display: grid;
  }
}

.document-item__size, .document-item__link-title {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;

  @include screen-medium-only {
    margin-top: 0;
    margin-bottom: 12px;
    order: 1;
  }

  @include screen-small-down {
    margin-right: 0;
  }

  @include screen-large-up {
    text-align: right;
    grid-column: span 2;
  }
}

.document-item__download {
  @include screen-medium-down {
    width: 100%;
  }
  @include screen-large-up {
    grid-column: 3 / span 1;
  }
}

.document-item__download--single {
  grid-column: 2 / span 2;
}

.site-search-wrapper:not(.opened) {
  & + .documents__filters-wrapper {
    @include indent-outer-top(-16);
    @include screen-small-down {
      padding-top: 0;
    }
    @include screen-medium-down {
      margin-top: 0;
    }
  }
}

.documents__filters-wrapper {
  @include indent-top(48);
  @include indent-bottom(20);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-dividing-line;

  &:last-child {
    border-bottom: none;
  }

  .pagination__wrapper + .documents__filter {
    @include screen-small-down {
      display: none;
    }
  }
}

.documents__filters-wrapper--final {
  @include indent-bottom(32);
  border-bottom: none;
}

.documents__filter {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  display: flex;
  align-items: center;
  gap: 0;

  .select {
    width: auto;

    &:hover {
      .text-field {
        color: $color-interactive-element-hover;
      }
    }

    &.focused,
    &.opened,
    &:hover {
      .text-field {
        border-color: transparent;
        outline: none;
        cursor: pointer;
      }

      &:after {
        box-shadow: none;
      }
    }

    .text-field {
      padding: 0;
      padding-left: 4px;
      padding-right: 0 !important;
      background: transparent;
      text-transform: lowercase;
    }

    .select__list-button {
      @include typo-make-sizes($typo-paragraph-small-sizes);
      @include indent-inner-horizontal-pair(16);
      @include indent-inner-vertical-pair(12);

      &.active {
        &::before {
          content: none;
        }
      }
    }

    .select__list-wrapper {
      @include indent-outer-left(-12);
      @include indent-outer-right(-16);
      top: calc(100% + 8px);
      z-index: 3;
    }
  }

  .text-field {
    color: $color-interactive-element;
  }

  & + .documents__filter {
    justify-content: flex-end;
  }
}

.documents__filter-title {
  color: $color-hint;

  @include screen-small-down {
    white-space: nowrap;
  }
}

.document-viewer__list {
  grid-column: span 6;

  .document-viewer__item:last-child {
    border-color: transparent;
  }
}

.document-viewer__list-title {
  margin-bottom: -4px;
  color: $color-field-frame;
  font-size: 12px;
  line-height: 16px;
}

.document-viewer__item {
  @include indent-inner-vertical-pair(20);
  display: flex;
  align-items: center;
  gap: 0;
  border-bottom: 1px solid $color-dividing-line-light;
  transition: border-color 0.1s linear;

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    border-color: $color-dividing-line;
  }

  input[type=radio] {
    @include indent-outer-right(80);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $color-text;
    cursor: pointer;
    transition: border 0.1s linear;

    @include screen-medium-down {
      @include indent-outer-right(24);
    }

    &.hidden {
      display: none;

      + .document-viewer__item-content {
        .icon {
          margin-left: 0;
        }
      }
    }

    &:hover {
      border-color: $color-heading;
      border-width: 4px;
    }

    &:checked {
      border-color: $color-interactive-element;
      border-width: 6px;
    }
  }
}

.document-viewer__item-content {
  display: flex;
  align-items: center;
  gap: 0;

  .icon {
    @include indent-outer-horizontal-pair(20);
    width: 48px;
    height: 48px;
  }
}

.document-viewer__item-title {
  @extend %typo-base-text-link;
  @extend %typo-base-text-link-underline;
  @include typo-make-sizes($typo-paragraph-text-sizes);

  &:hover {
    @extend %typo-base-text-link-underlined;
  }
}

.document-viewer__item-type {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
  margin-top: 4px;
}

.document__iframe-container {
  @include indent-outer-bottom(32);
  padding-top: 56.25%;
  position: relative;
  transition: opacity 0.3s linear;

  @include screen-medium-down {
    padding-top: 669px;
  }

  &.fadeIn {
    opacity: 0;
  }

  img, iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.document__text-content {
  grid-column: 1 / span 4;
  @include indent-bottom(64);

  &:last-child {
    @include indent-bottom(40);
  }

  h2 {
    @include indent-bottom(24);
    font-weight: 600;
  }

  .contact__link + .contact__link {
    @include indent-top(32);
  }

  .contact__link {
    &:last-child {
      @include indent-bottom(32);
    }
  }

  .document__controller-wrapper {
    @include indent-inner-vertical-pair(8);
    display: flex;
    align-items: center;

    .article__button-wrapper {
      @include indent-outer-left(32);
    }
  }
}

.document-item__link-title {
  @extend %typo-text-link-arrow;
  position: relative;
}
