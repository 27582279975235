////
/// Styles for the custom "Site Search" component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/text-field";
@use "~@make/ds2/scss/components/button";
@use "~@make/ds2/scss/placeholders";
@use "~@make/ds2/scss/components/select";


.site-search-wrapper {
  @include indent-top(32);
  @include grid-container;
  @include grid-standard;
}

.site-search-wrapper--documents {
  @include indent-bottom(40);
}

.site-search-wrapper--error-page {
  @include indent-top(24);
  @include indent-bottom(80);
}

.site-search-inner-wrapper {
  @include indent-top(32);
  @include indent-bottom(40);
  @include grid-standard;
  display: grid;
}

.site-search-wrapper--compact {
  padding-bottom: 0;
}

.page-section__header + .site-search-inner-wrapper {
  padding-top: 0;
}

.site-search {
  grid-column: 1 / span 2;
  min-width: 0;

  &.loaded {
    .site-search__input {
      box-shadow: 0 0 0 4px $color-interactive-element-outline;
    }

    .site-search__results-wrapper {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @include screen-medium-up {
    grid-column: 1 / span 4;
  }
}

.site-search__input-wrapper {
  position: relative;
  z-index: 2;
}

.site-search__placeholder {
  position: absolute;
  display: flex;
  gap: 0;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.site-search__placeholder-text {
  @include indent-left(16);
  @include indent-right(40);
  color: $color-hint;
  transition: opacity 0.1s linear;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.site-search__placeholder-example {
  display: none;

  @include screen-medium-up {
    display: inline;
  }
}

.site-search__placeholder-suggestion {
  @extend %typo-base-text-link;
  cursor: pointer;
  pointer-events: auto;
}

.site-search__input {
  @extend .text-field;

  &:focus, &.not-empty {
    + .site-search__placeholder {
      .site-search__placeholder-text {
        opacity: 0;
      }

      .site-search__placeholder-suggestion {
        pointer-events: none;
      }
    }
  }

  &:focus + .site-search__placeholder + .site-search__controls {
    .site-search__submit .icon {
      fill: $color-text;
    }
  }

  &.not-empty + .site-search__placeholder + .site-search__controls {
    .site-search__clear,
    .site-search__separator {
      opacity: 1;
    }

    .site-search__clear {
      pointer-events: auto;
    }
  }
}

.site-search__queries {
  @include indent-vertical-pair(20);
  @include indent-outer-bottom(-20);
  display: flex;
  min-width: 0;
  gap: 0;
  margin-right: - $grid-padding-tiny;

  @include screen-small-up {
    margin-right: - $grid-padding-small;
  }

  @include screen-medium-up {
    margin-right: - $grid-padding-medium;
  }

  @include screen-large-up {
    margin-right: auto;
    flex-wrap: wrap;
  }

  @include screen-medium-down {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.site-search__query-item {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  @include indent-outer-bottom(20);
  @include indent-right(24);
  display: flex;
  flex-shrink: 0;
  min-width: 0;
  color: $color-hint;
  opacity: 1;
  transition: opacity 0.1s ease-in;

  &:hover {
    .icon {
      fill: $color-interactive-element-hover;
    }
  }

  &.hidden {
    opacity: 0;
    display: none;
    overflow: hidden;
  }

  &.opened {
    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
    position: relative;
    margin-top: -2px;
    fill: $color-text;
    transition: fill 0.1s linear, transform 0.1s linear;

    @include screen-medium-down {
      display: none;
    }
  }

  &.active {
    .site-search__query-link {
      font-weight: 600;
    }
  }
}

.site-search__query-item--title {
  @include screen-small-down {
    display: none;
  }
}

.site-search__query-link {
  @extend .button;
  @extend .button--secondary;
  @extend .button--small;
  @include typo-make-sizes($typo-paragraph-small-sizes);
  display: inline-flex;
  padding: 3px 8px 5px;

  @include screen-large-up {
    padding: 0;
    border-radius: 0;
    color: $color-text;
    transition: color 0.1s linear, outline-color 0.1s linear;

    &:before, .wave-wrapper {
      display: none;
    }

    &:focus {
      outline: 4px solid $color-interactive-element-outline;
      outline-offset: 2px;
    }
  }
}


.site-search__controls {
  @include indent-base(16, right);
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.site-search__clear,
.site-search__submit {
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 1;

  @include screen-medium-up {
    width: 24px;
    height: 24px;
  }

  &:hover {
    .icon {
      fill: $color-text;
    }
  }

  .icon {
    fill: $color-field-frame;
    width: inherit;
    height: inherit;
  }

  .wave--active {
    background-color: $color-light-gray-fill;
  }
}

.site-search__submit {
  pointer-events: auto;
}

.site-search__clear {
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.1s linear;
}

.site-search__separator {
  @include indent-outer-horizontal-pair(16);
  height: 32px;
  border-left: 1px solid $color-dividing-line-light;
  opacity: 0;
  transition: opacity 0.1s linear;
}

.site-search__results-wrapper {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  max-height: 72px * 3 + 48px * 2;
  border-radius: 2px;
  box-shadow: 0 10px 12px rgba($color-heading, 0.04);
  transition: opacity 0.1s linear;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
}

.site-search__results {
  @extend %helper-hidden-system-scrollbar;
  overflow-y: scroll;
  height: 100%;
  max-height: inherit;
}

.site-search__results-item {
  display: flex;
}

.site-search__results-button {
  @include indent-horizontal-pair(16);
  @include indent-vertical-pair(12);
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  display: flex;
  gap: 0;
  width: 100%;
  align-items: center;
  background-color: $color-white;
  transition: background-color 0.1s linear;

  &:hover, &:focus {
    background-color: $color-light-gray-fill;
  }
}

.site-search__results-image-wrapper {
  @include indent-base(48, width height);
  @include indent-outer-right(16);
  flex-shrink: 0;
  align-self: flex-start;
  border-radius: 2px;
  overflow: hidden;
}

.site-search__results-image-wrapper,
.site-search__results-text-container {
  pointer-events: none;
}

.site-search__results-image {
  width: 100%;
  height: 100%;
  image-rendering: high-quality;
  object-fit: cover;
}

.site-search__results-hint {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  margin-top: 4px;
  display: block;
  color: $color-hint;
}
