////
/// Design system's secondary colors.
/// @group secondary-colors
/// @type Color
////

/// Cover color.
$color-cover: #f3f3f3 !default;

///Gray color.
$color-gray: #979797 !default;