@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.public-banner__message-block {
  padding: 16px 0;
  background: #0061D9;
  display: none;

  &.active {
    display: block;
  }
}

.public-banner__message-block-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.public-banner__message-block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  max-width: 75%;
}

.public-banner__message-block-close {
  cursor: pointer;

  svg {
    fill: white;
    width: 15px;
    height: 15px;
  }
}


.public-banner__top-block-slider, .public-banner__bottom-block-slider {
  margin: 0 auto;
  margin-bottom: 80px;
  max-width: 1480px;
  padding-left: 40px;
  padding-right: 40px;
  display: none;

  &.active {
    display: block;
  }

  .constructor-slider__button {
    display: none;

    &.active {
      display: flex;
    }
  }
}

.public-banner__top-block-scripts, .public-banner__bottom-block-scripts {
  margin-bottom: 80px;
  display: none;

  &.active {
    display: block;
  }
}