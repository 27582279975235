@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.constructor-form-in-text {
  @include indent-outer-bottom(48);

  .constructor-checkbox__wrapper{
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 0;
  }
}

.constructor-form-in-text__submit {
  height: 48px;
  align-self: end;
}

.constructor-form-in-text__submit--cod-confirmation{
  width: 50%;
  align-self: start;
}

.constructor-form-in-text__submit--cod-confirmation-description{
  opacity: 0;

  &.active{
    opacity: 1;
  }
}


