@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;

.constructor-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  padding: 10px 16px;
  transition: color 0.1s linear;
  z-index: 1;
  font-size: 16px;
  line-height: 24px;
  flex-shrink: 0;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    transition: box-shadow 0.1s linear, background-color 0.1s linear,
    border-color 0.1s linear, top 0.1s linear, left 0.1s linear,
    right 0.1s linear, bottom 0.1s linear;
    z-index: -2;
    content: '';
  }

  // .wave-wrapper {
  //   transition: top 0.1s linear,
  //     left 0.1s linear,
  //     right 0.1s linear,
  //     bottom 0.1s linear;
  // }

  // &[disabled] {
  //   pointer-events: none;

  //   .wave {
  //     background-color: transparent;
  //   }
  // }
}

.constructor-button--big {
  padding: 16px 24px;
  border-radius: 2px;
}

.constructor-button--large {
  justify-content: flex-start;
  padding: 24px 24px 32px 24px;
  width: 348px;
  height: 80px;
  border-radius: 2px 2px 0 0;
}

.constructor-button--primary,
.constructor-button--secondary,
.constructor-button--contrast {
  &:active {
    &:before,
    .wave-wrapper {
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
    }
  }

  &[disabled] {
    .wave-wrapper {
      display: none;
    }
  }
}

.constructor-button--primary {
  color: $color-white;

  &:not(.admin-button) {
    &:before {
      background-color: var(--color-interactive-element);
    }

    &:focus:not(:active):not(:hover):not([disabled]):before {
      background-color: var(--color-interactive-element);
    }

    .wave--hover {
      background-color: var(--color-interactive-element-hover);
    }

    .wave--active {
      background-color: var(--color-interactive-element-hover-rgba);
    }
  }

  &:focus:not(:active):not(:hover):not([disabled]):before {
    background-color: $color-interactive-element;
  }

  &:before {
    background-color: $color-interactive-element;
  }

  .wave--hover {
    background-color: $color-interactive-element-hover;
  }

  .wave--active {
    background-color: $color-interactive-element-active;
  }

  &[disabled] {
    color: $color-field-frame;

    &:before {
      background-color: $color-dividing-line;
    }
  }
}

.constructor-button--secondary {
  color: $color-interactive-element;

  &:before {
    background-color: transparent;
    border: 1px solid $color-interactive-element-outline;
  }

  &:focus:not(:active):not(:hover):not([disabled]):before {
    background-color: transparent;
    box-shadow: 0 0 0 4px $color-interactive-element-outline;
  }

  &:hover {
    color: $color-interactive-element-hover;

    &:before {
      border-color: $color-interactive-element;
    }
  }

  &:active {
    color: $color-interactive-element-active;

    &:before {
      border-color: $color-interactive-element-active;
    }
  }

  .wave--hover {
    background-color: rgba($color-interactive-element-hover, 0.08);
  }

  .wave--active {
    background-color: rgba($color-interactive-element-hover, 0.12);
  }

  &[disabled] {
    color: $color-field-frame;

    &:before {
      background-color: rgba($color-heading, 0.04);
      border-color: $color-dividing-line;
    }
  }
}

.constructor-button--secondary:not(.admin-button) {
  color: var(--color-interactive-element);

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:before {
    border-color: var(--color-interactive-element);
  }

  .wave--hover {
    background-color: var(--color-interactive-element-hover-rgba);
  }

  .wave--active {
    background-color: var(--color-interactive-element-hover-rgba);
  }

}

.constructor-button--contrast {
  color: $color-white;

  &:before {
    background-color: transparent;
    border: 1px solid rgba($color-white, 0.32);
  }

  &:focus:not(:active):not(:hover):not([disabled]):before {
    background-color: transparent;
    box-shadow: 0 0 0 4px $color-interactive-element-outline-dark;
  }

  &:hover {
    &:before {
      border-color: rgba($color-white, 0.48);
    }
  }

  &:active {
    &:before {
      border-color: rgba($color-white, 0.64);
    }
  }

  .wave--hover {
    background-color: rgba($color-white, 0.08);
  }

  .wave--active {
    background-color: rgba($color-white, 0.12);
  }
}


.constructor-component__add-new-component {
  position: absolute;
  top: calc(100% - 24px);
  left: calc(50% - 115px);
  cursor: pointer;
  width: 230px;
  height: 48px;
  display: none;
  padding: 10px 0;
  z-index: 2;
  align-items: center;

  svg {
    fill: white;
    margin-right: 12px;
  }
}
