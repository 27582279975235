////
/// Styles for the article page.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";
@use "~@make/ds2/scss/typography";

.page-article {
  background-color: $color-light-gray-fill;
}

.article-section {
  @include grid-container;
  @include screen-medium-down {
    overflow: hidden;
  }
}

.article__header {
  @include indent-outer-bottom(40);
  @include indent-vertical-pair(32);
  border-bottom: 1px solid $color-dividing-line;

  .back-link {
    @include indent-outer-bottom(20);
    order: 0;
  }
}

.article__photo-album-header {
  @include indent-bottom(20);
}

.article__title {
  @include typo-make-sizes($typo-heading-h1-article-sizes); // TODO h1 size 48
  font-weight: 400;
}

.article__content-wrapper {
  @include grid-standard;
  display: grid;

  @include screen-small-down {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  @include screen-large-up {
    grid-template-columns: repeat(6, 1fr);
  }
}

.article__lead {
  @include typo-make-sizes($typo-paragraph-lead-sizes);
  @include indent-bottom(48);
  grid-column: span 4;

  @include screen-medium-down {
    grid-column: 1 / span 3;
  }

  @include screen-large-up {
    grid-column: 2 / span 4;
  }

  & + .article__content > img:first-child,
  & + .article__content > .article__image-wrapper,
  & + .article__content > .youtube-player,
  & + .article__content > .constructor-youtube-player {
    @include screen-large-up {
      max-width: calc(150% + 20px);
      margin-left: calc(-25% - 10px);
      margin-right: calc(-25% - 10px);
      object-fit: cover;
    }
  }

  & + p {
    @include indent-outer-top(-24);
  }
}

.article__content--with-border {
  @include indent-bottom(32);
  border-bottom: 1px solid $color-dividing-line;
}

.article__header-info {
  @include indent-outer-top(32);
  @include indent-outer-bottom(-12);
  gap: 0;

  @include screen-small-down {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include screen-medium-up {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .article__publication-date {
    margin-bottom: 0;
  }

  .article__publication-info {
    display: flex;
    align-items: center;

    @include screen-small-down {
      flex-direction: row;
      justify-content: flex-start;
    }

    @include screen-medium-up {
      margin-bottom: 0;
    }

    &.content-block {
      @include screen-small-down {
        margin-bottom: 0;
      }
    }

    .article__publication-media-count {
      &:not(:only-child) {
        &::before {
          margin-left: 8px;
        }
      }
    }
  }

  .article__publication-date {
    @include screen-small-down {
      margin-left: 0;
    }
  }

  .article__publication-date + .article__publication-views {
    @include indent-outer-left(24);
  }
}

.article__publication-date {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  @include indent-outer-bottom(12);
  color: $color-text;
  display: inline-flex;
  gap: 0;

  @include screen-small-down {
    @include indent-outer-left(16);
    margin-bottom: 0;
  }

  .icon {
    @include indent-outer-right(8);
    width: 20px;
    height: 20px;
    fill: $color-hint;
  }
}

.article__publication-place {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-text;
  display: inline-flex;
  gap: 0;

  &::before {
    @include indent-outer-left(8);
    @extend %typo-text-bullet;
    padding-right: 8px;
    display: inline-flex;
  }
}

.article__publication-views {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 0;
  color: $color-hint;

  .icon {
    @include indent-outer-right(8);
    width: 20px;
    height: 20px;
    fill: $color-hint;
  }
}

.article__publication-media-count {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;

  &:not(:only-child) {
    &::after {
      margin-left: 8px;
    }
  }

  &::before {
    @extend %typo-text-bullet;
    padding-right: 8px;
    display: inline-flex;
  }
}

.article__media-controllers {
  display: flex;
  align-items: center;

  @include screen-small-down {
    display: none;
  }
}

.article__media-controller {
  @include indent-outer-left(24);
  display: flex;
  align-items: center;
  color: $color-hint;
  transition: color 0.1s linear;

  &:first-child {
    margin-left: 0;
  }

  .icon {
    margin-right: 8px;
    fill: $color-hint;
    transition: fill 0.1s linear;
  }

  &.active {
    color: $color-text;

    .icon {
      fill: $color-text;
    }
  }
}

.article__content-wrapper {
  position: relative;
}

.article__content {
  @include indent-bottom(80);
  grid-column: 2/span 4;
  display: flex;
  flex-direction: column;
  min-width: 0;

  @include screen-medium-down {
    grid-column: 1 / span 4;
  }

  .news__illustration-image, .news__illustration-video {
    object-fit: contain;
  }

  .article__documents-wrapper {
    @include indent-bottom(48);
    @include indent-outer-bottom(-20);

    + h2, h3 {
      @include indent-outer-top(12);
    }
  }

  .article__map-wrapper {
    @include indent-outer-bottom(-48);
  }

  a:not([class]) {
    align-self: flex-start;
  }

  > .constructor-documents-list-item {
    z-index: 1;
  }

  .constructor-contacts-with-map__map {
    @include indent-outer-top(32);
  }

  > p + .constructor-contacts-with-map__map {
    @include indent-outer-top(12);
  }

  > a, a:not(.constructor-contact-person-item__link,
  .person-info,
  .government-structure__department-link,
  .constructor-documents-list-item,
  .constructor-map-point-item__phone,
  .constructor-map-point-item__email) {
    @extend %typo-base-text-link;
    @extend %typo-base-text-link-underline;
    @include indent-outer-right(16);
    align-self: flex-start;
    display: inline-flex;
    gap: 0;
    z-index: 2;
    border-bottom-color: var(--color-interactive-element-rgba);

    &:hover {
      @extend %typo-base-text-link-underlined;

      .hint-popup__person {
        @include screen-medium-up {
          opacity: 1;
        }

        @include screen-medium-down {
          transform: translateY(-8px);
        }

        @include screen-medium-up {
          transform: translateX(-25%) translateY(-8px);
        }
      }
    }

    &[href^="http"] {
      @extend %typo-text-link-arrow;

      &:after {
        display: inline-flex;
      }

      &:hover {
        @extend %typo-text-link-arrow-hover;
      }
    }

    &:not(.constructor-documents-list-item, .government-structure__department-link) {
      &:not([href^="http"]) {
        @extend %typo-text-link-bullet;
      }
    }
  }

  > img:not([class]),
  .blockquote, blockquote:not(.blockquote), img,
  .article__image-wrapper,
  .youtube-player {
    &:not(:first-child) {
      @include indent-outer-top(48);
    }

    &:not(:last-child) {
      @include indent-outer-bottom(64);
    }
  }

  > img:not([class]) {
    @include screen-large-up {
      max-width: calc(150% + 20px);
      margin-left: calc(-25% - 10px);
      margin-right: calc(-25% - 10px);
    }
  }

  > img:not([class]) + img:not([class]),
  img + img,
  > .article__image-wrapper + .article__image-wrapper,
  img + .youtube-player,
  .youtube-player + img,
  img + .constructor-youtube-player,
  .constructor-youtube-player + img {
    margin-top: 0;
  }

  > img:not([class]) + .image-caption,
  img + .image-caption,
  .article__image-wrapper + .image-caption,
  .youtube-player + .image-caption {
    @include typo-make-sizes($typo-paragraph-small-sizes);
    color: $color-hint;
    margin-top: -24px;

    @include screen-medium-up {
      @include indent-outer-top(-48);
    }

    @include screen-large-up {
      margin-left: calc(-25% - 10px);
    }
  }

  .constructor-youtube-player + .image-caption {
    @include indent-outer-top(12);
    @include typo-make-sizes($typo-paragraph-small-sizes);
    color: $color-hint;

    .spoiler__button {
      @include indent-outer-top(8);
    }
  }

  .static-form {
    @include indent-outer-top(20);
  }

  .constructor-input-field__label-wrapper {
    margin-bottom: 4px;
  }

  .constructor-input-field__label {
    @include typo-make-sizes($typo-paragraph-text-sizes);
  }

  .article__image--vertical {
    max-width: none;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: auto;

    &:not(.article__image) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  > ul:not([class]), > ol:not([class]) {
    &:not(:last-child) {
      @include indent-outer-bottom(32);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  > p {
    @include indent-outer-bottom(20);

    &:last-child {
      margin-bottom: 0;
    }

    + .article__image {
      @include indent-outer-top(20);
    }

    + .article__documents-wrapper {
      @include indent-outer-top(20);
    }

    + .constructor-youtube-player {
      @include indent-outer-top(20);
    }
  }

  .static-form {
    @include indent-outer-bottom(-20);

    input, textarea {
      @include typo-make-sizes($typo-paragraph-text-sizes);
    }

    .constructor-button {
      @include typo-make-sizes($typo-paragraph-text-sizes);
      display: flex;
      align-items: center;
    }
  }

  .youtube-player,
  .constructor-youtube-player {
    + {
      h1, h2, h3, h4 {
        margin-top: 0;
      }
    }
  }

  > {
    h1, h2, h3, h4 {
      &:not([class]) + {
        ul:not([class]), ol:not([class]) {
          margin-top: 0;
        }
      }
    }
  }

  > h2 {
    @include indent-outer-bottom(32);
    @include indent-outer-top(48);
  }

  > h3 {
    @include indent-outer-bottom(24);
    @include indent-outer-top(32);
  }

  > h4 {
    @include indent-outer-bottom(20);
    @include indent-outer-top(32);
  }

  p + h2 {
    @include indent-outer-top(24);
  }

  p + h3,
  p + h4 {
    @include indent-outer-top(12);
  }

  blockquote, ol, ul, blockquote:not(.blockquote) {
    + {
      h2, h3, h4 {
        margin-top: 0;
      }
    }
  }

  p {
    &:not([class]) + {
      ul:not([class]), ol:not([class]) {
        margin-top: 0;
      }
    }
  }

  blockquote {
    @include grid-standard;
    display: grid;
    background-color: white;

    @include screen-small-down {
      @include grid-template(1 4 1);
      @include indent-outer-horizontal-pair(-40);
      @include indent-horizontal-pair(40);
    }

    @include screen-medium-only {
      display: flex;
      flex-wrap: wrap;
      @include indent-outer-horizontal-pair(-80);
      @include indent-horizontal-pair(80);
    }

    @include screen-large-up {
      max-width: calc(150% + 20px);
      margin-left: calc(-25% - 10px);
      margin-right: calc(-25% - 10px);
    }
  }

  iframe {
    width: 100%;
  }

  .constructor-contact-person-item {
    @include screen-small-down {
      display: flex;
      flex-direction: column;
    }

  }

  .constructor-contact-person {
    margin-bottom: 0;
  }

  .constructor-contact-person-item__last-name {
    font-weight: 700;
  }
}

.article__image-wrapper,
.youtube-player {
  padding-top: 56.25%;
  position: relative;

  @include screen-large-up {
    max-width: calc(150% + 20px);
    margin-left: calc(-25% - 10px);
    margin-right: calc(-25% - 10px);
    padding-top: calc((150% + 20px) * 0.5625);
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
  }
}

.article__image {
  object-fit: contain;

  @include screen-large-up {
    max-width: calc(150% + 20px);
    margin-right: calc(-25% - 10px);
  }
}

.article__controllers-wrapper {
  @include indent-vertical-pair(32);


  &:not(:last-child) {
    border-bottom: 1px solid $color-dividing-line;
  }

  @include screen-small-down {
    @include indent-outer-horizontal-pair(-32);
    @include indent-horizontal-pair(32);
    padding-top: 0;
  }

  @include screen-medium-only {
    margin: 0;
    grid-template-columns: repeat(8, 1fr);
  }

  .article__publication-info {
    display: flex;
    align-items: center;

    @include screen-small-down {
      display: none;
    }

    @include screen-medium-only {
      grid-column: 1 / span 2;
    }
  }
}

.article__publication-info {
  @include screen-small-down {
    @include indent-outer-bottom(16);
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @include screen-medium-down {
    grid-column: 1 / span 1;
  }

  & + .article__lead {
    @include screen-medium-only {
      grid-column: 2 /span 3;
    }
  }
}

.article__buttons-wrapper {
  grid-column: span 5;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    grid-column: span 6;
  }

  @include screen-medium-down {
    grid-column: span 6;
  }
}

.article__button-wrapper {
  @include screen-medium-only {
    grid-column: span 3;
  }

  .button {
    @include screen-medium-only {
      white-space: nowrap;
    }
  }

  & + .article__button-wrapper {
    @include screen-small-down {
      margin-bottom: 0;
    }

    @include screen-medium-up {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.article__controller-button {
  @extend %typo-text-link-menu;
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  display: flex;
  align-items: center;
  gap: 0;

  .icon {
    @include indent-outer-right(12);
  }

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active)
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}

.article__controller-button-text {
  pointer-events: none;

  @include screen-medium-down {
    display: none;
  }
}

.article__controller-button-text--mobile {
  pointer-events: none;

  @include screen-large-up {
    display: none;
  }
}

.article__topics-wrapper {
  @include indent-top(48);
  @include indent-bottom(64);

  @include screen-medium-down {
    margin-right: -24px;
    padding-right: 24px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include screen-medium-only {
    margin: 0;
  }
}

.article__topics-title {
  @include screen-small-down {
    display: none;
  }
}

.article__topics {
  margin-right: auto;
  display: flex;
  min-width: 0;
  gap: 0;
  grid-column: span 5;

  @include screen-medium-only {
    grid-column: 2 / span 3;
  }

  .article__topic-item {
    @include indent-outer-right(16);

    @include screen-small-down {
      @include indent-outer-bottom(16);
    }
  }

  .article__topic-link {
    padding: 6px 8px;

    @include screen-medium-down {
      white-space: nowrap;
      display: inline-flex;
    }
  }
}

.atricles__dense-list--wrapper {
  @include indent-top(48);
  @include indent-bottom(80);
  border-top: 1px solid $color-dividing-line;
}

.atricles__dense-list {
  @include indent-outer-horizontal-pair(-32);

  @include screen-small-down {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  @include screen-medium-down {
    @include indent-outer-bottom(-40);
  }

  @include screen-large-up {
    @include indent-outer-horizontal-pair(-40);
  }

  .news {
    @include screen-medium-down {
      @include indent-outer-bottom(40);
    }
  }

  .news__illustration {
    height: 56.25%;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(180deg, rgba($color: $color-heading, $alpha: 0.0001) 0.24%, rgba($color: $color-heading, $alpha: 0.32) 100%)
    }

    img {
      position: absolute;
    }
  }
}

.article__gallery-wrapper {
  @include grid-container;

  .article-slider__pagination-wrapper,
  .article-slider__button--prev,
  .article-slider__button--next {
    display: none;
    transition: opacity 0.25s linear, transform 0.2s ease-out;
  }

  .image-caption {
    display: none;
  }

  .article-slider__list {
    @include indent-outer-bottom(-40);
  }

  .article__slider {
    .swiper-slide-prev,
    .swiper-slide-next,
    .swiper-slide-duplicate-next,
    .swiper-slide-duplicate-prev {
      opacity: 1;
    }
  }

  .article-slider__list {
    display: grid;
    @include screen-small-down {
      grid-template-columns: repeat(2, 1fr);
    }

    @include screen-medium-only {
      grid-template-columns: repeat(4, 1fr);
    }

    @include screen-large-up {
      grid-template-columns: repeat(12, 1fr);
    }

    &.gallery--long {
      .article-slide {
        &:first-child {
          @include screen-small-down {
            grid-column: span 2;
          }

          @include screen-large-up {
            grid-column: 1 / span 6;
            grid-row: 1 / span 2;
          }
        }
      }
    }

    .article-slide {
      @include indent-outer-bottom(40);
      display: flex;
      grid-column: span 2;
      height: auto;

      @include screen-small-down {
        grid-column: span 1;
      }

      @include screen-large-up {
        grid-column: span 3;
      }

      &:hover {
        cursor: pointer;

        .article-slide__open-controller {
          opacity: 1;
          pointer-events: all;
          z-index: 1;
        }
      }
    }
  }

  .article-slider__button-wrapper {
    display: block;
  }
}

.article__document-info {
  .document-item__info {
    flex-direction: row;
  }

  .document-item__detail-items {
    margin-bottom: 0;
    @include screen-small-down {
      margin-bottom: -16px;
    }
  }

  .document-item__number {
    display: flex;
    margin-bottom: 0;

    &:not(:only-child) {
      &:after {
        @extend %typo-text-bullet;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  .document-item__date {
    margin-bottom: 0;
  }

  .document-item__info {
    & + .document-item__detail-items {
      @include indent-outer-top(8);

      .document-item__detail-item {
        @include screen-medium-down {
          @include indent-outer-bottom(8);
          display: inline;
        }

        @include screen-large-up {
          margin-bottom: 0;
        }
      }
    }
  }

  .document-item__detail-title {
    @include screen-medium-down {
      display: inline;
    }
  }

  .document-item__number-link {
    margin-right: 4px;
    @include screen-medium-down {
      display: inline;
    }
  }
}

.article__content-iframe {
  padding: 24px 0;
}
