////
/// The custom styles for the tabs component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/components/tabs";
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";


// TODO: revisit an issue with repeating .content-block styles declarations
.tabs__content-block {
  @include indent-top(40);

  h2, h3, p.nav,
  .lead, .lead-big, .inner-row, > .input-field {
    @include indent-bottom(24);
  }

  .lead + {
    h2, h3 {
      @include indent-top(16);
    }
  }

  .lead-big + .person-info-list--wide {
    @include indent-top(16);
  }

  .media-list,
  .news-section--with-button .news--row:first-child {
    padding-top: 0;
  }

  .news-section__button-wrapper {
    margin-bottom: 0;
  }

  p + .important-links {
    &:not(:first-child) {
      @include indent-top(12);
    }
  }

  .important-links {
    &:not(:last-child) {
      @include indent-bottom(48);
    }
  }

  p:not([class]):not(:last-child) {
    @include indent-bottom(20);
  }

  dl {
    &:not(:last-child) {
      @include indent-bottom(32);
    }

    @include grid-medium-up(1 1 1 1) {
      display: grid;

      dd {
        grid-column: span 3;
      }
    }
  }

  dt {
    color: $color-hint;

    @include screen-small-down {
      padding-bottom: 4px;
    }
  }

  dd {
    &:not(:last-child) {
      @include indent-bottom(20);
    }
  }

  a:not([class]) {
    @extend %typo-base-text-link;
    @extend %typo-base-text-link-underline;
    @extend %typo-base-text-link-underlined;
    @extend %typo-text-link-external-url-arrow;
    border-bottom-color: $color-interactive-element-outline;
    margin-right: 16px;

    &:hover {
      border-bottom-color: $color-interactive-element-outline-dark;
    }
  }
}

.tabs__content-block--text {
  @include grid-standard;
  display: grid;

  .content-block {
    grid-column: span 2;

    @include screen-medium-up {
      grid-column: span 4;
    }
  }
}

.tabs__button.active {
  color: var(--color-interactive-element);

  &:after {
    border-color: var(--color-interactive-element);
  }

  &:focus .tabs__button-text {
    outline-color: var(--color-interactive-element-rgba);
  }
}
