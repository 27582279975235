////
/// Styles for the "Site Footer" component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/site-menu";
@use "~@make/ds2/scss/components/button";


$site-footer-text-sizes: (14, 14, 14, 16, 16) !default;
$site-footer-small-text-sizes: (12, 12, 12, 14, 14) !default;
$site-footer-padding-top-mobile: 32px !default;
$site-footer-padding-top-desktop: 48px !default;
$site-footer-padding-bottom-mobile: 24px !default;
$site-footer-padding-bottom-tablet: 16px !default;
$site-footer-padding-bottom-desktop: 24px !default;
$site-footer-logotype-width-mobile: 36px !default;
$site-footer-logotype-height-mobile: 36px !default;
$site-footer-logotype-width-tablet: 44px !default;
$site-footer-logotype-height-tablet: 44px !default;
$site-footer-logotype-width-desktop: 48px !default;
$site-footer-logotype-height-desktop: 48px !default;


.site-footer {
  @include typo-make-sizes($site-footer-text-sizes);
  background-color: $color-white;
  padding-top: $site-footer-padding-top-mobile;
  padding-bottom: $site-footer-padding-bottom-mobile;

  @include screen-medium-up {
    padding-bottom: $site-footer-padding-bottom-tablet;
  }

  @include screen-large-up {
    padding-top: $site-footer-padding-top-desktop;
    padding-bottom: $site-footer-padding-bottom-desktop;
  }
}

.site-footer__wrapper {
  @include grid-container;
  @include grid-standard;

  & + .site-footer__wrapper {
    position: relative;

    &::before {
      @include indent-outer-horizontal-pair(40);
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      background-color: $color-dividing-line-light;
      left: 0;
      right: 0;
      top: 0;
      @include screen-medium-down {
        @include indent-outer-horizontal-pair(32);
      }
    }
  }
}

.site-footer__contacts {
  grid-column: 1 / span 2;

  @include screen-small-down {
    padding-bottom: 20px;
  }

  @include screen-medium-only {
    @include grid-template(1 1);
    display: grid;
    grid-column: 1 / span 4;
    padding-bottom: 32px;
  }
}

.site-footer__address,
.site-footer__telephone {
  color: $color-hint;
}

.site-footer__address-line,
.site-footer__telephone-link {
  color: $color-text;
}

.site-footer__telephone,
.site-footer__email {
  @include indent-outer-top(12);
  @include screen-medium-only {
    grid-column: 1;
  }
}

.site-footer__telephone-link {
  @extend %typo-text-link-menu;

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}

.site-footer__email-link {
  color: var(--color-interactive-element);
  transition: color 0.1s linear;

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }
}

.site-footer__social-links {
  @include indent-top(32);
  display: flex;
  padding-top: 16px;
  gap: 0;

  @include screen-medium-only {
    padding-top: 0;
    justify-self: end;
    grid-row: 1;
    grid-column: 2;
  }

  @include grid-huge-up(1 1) {
    display: grid;
    gap: inherit;
    padding-top: 32px;
    margin-bottom: -12px;
  }
}

.site-footer__social-link-item {
  display: inline-flex;
  margin-right: 12px;

  @include screen-huge-up {
    &:nth-child(2n) {
      margin-left: -$grid-gutter-huge * 0.5;
    }

    margin-right: 0;
    margin-bottom: 12px;
  }

  @include screen-min($grid-container-content-huge-max) {
    &:nth-child(2n) {
      margin-left: -$grid-gutter-huge;
    }
  }
}

.site-footer__social-link {
  @include screen-large-down {
    .icon {
      margin-right: 0;
    }
  }
}

.site-footer__social-link-text {
  display: none;

  @include screen-huge-up {
    display: inline;
  }
}

.site-footer__menu-wrapper {
  grid-column: span 2;

  &:not(:first-child) {
    grid-column: 1 / span 2;

    @include screen-large-up {
      grid-column: 3 / span 2;
    }
  }
}

.site-footer__menu-wrapper--long {
  &:not(:first-child) {
    @include screen-large-up {
      grid-column: 3 / span 3;
    }
  }
}

.site-footer__menu {
  @include indent-outer-bottom(-12);
  @include grid-template(1);
  @include grid-medium-up(1 1);
  display: grid;
}

.site-footer__menu-item {
  @include indent-bottom(12);

  //&:nth-child(2n) {
  //  @include screen-large-up {
  //    margin-left: -$grid-gutter-large;
  //  }
  //
  //  @include screen-huge-up {
  //    margin-left: -$grid-gutter-huge * 1.5;
  //  }
  //
  //  @include screen-min($grid-container-content-huge-max) {
  //    margin-left: -$grid-gutter-huge * 2;
  //  }
  //}
}

.site-footer__other-links-wrapper {
  grid-column: 1 / span 2;

  @include screen-medium-up {
    grid-column: 3 / span 2;
  }

  @include screen-large-up {
    grid-column: 5 / span 2;
  }
}

.site-footer__about {
  @include indent-outer-top(48);
  @include indent-bottom(20);
  position: relative;
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  gap: 0;

  @include screen-medium-up {
    grid-column: 1 / span 4;
  }

  @include screen-large-up {
    grid-column: 1 / span 4;
  }
}

.site-footer__about,
.site-footer__menu-wrapper,
.site-footer__other-links-wrapper {
  @include screen-small-down {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $color-dividing-line-light;
  }
}

.site-footer__about-logotype {
  display: none;
  flex-shrink: 0;
  object-fit: contain;

  @include screen-medium-up {
    max-width: $site-footer-logotype-width-mobile;
    max-height: $site-footer-logotype-height-mobile;
    width: $site-footer-logotype-width-mobile;
    height: $site-footer-logotype-height-mobile;
  }

  @include screen-large-up {
    display: block;
    max-width: $site-footer-logotype-width-tablet;
    max-height: $site-footer-logotype-height-tablet;
    width: $site-footer-logotype-width-tablet;
    height: $site-footer-logotype-height-tablet;
  }

  @include screen-huge-up {
    width: $site-footer-logotype-width-desktop;
    height: $site-footer-logotype-height-desktop;
    max-width: $site-footer-logotype-width-desktop;
    max-height: $site-footer-logotype-height-desktop;
  }
}

.site-footer__about-text {
  font-weight: 500;

  @include screen-medium-down {
    margin-left: 0;
  }

  @include screen-medium-up {
    // margin-left: 12px;
  }

  &:not(:first-child) {
    @include screen-large-up {
      margin-left: 16px;
    }
  }
}

.site-footer__about-copyright {
  @include indent-top(20);
  @include typo-make-sizes($site-footer-small-text-sizes);
  display: block;
  font-weight: 400;
  color: $color-hint;

  @include screen-small-down {
    grid-row: 1;
    grid-column: 1;
  }

  @include screen-large-up {
    grid-column: 1 / span 2;
  }
}

.site-footer__disclaimer {
  @include typo-make-sizes($site-footer-small-text-sizes);
  grid-column: 1 / span 2;
  color: $color-hint;

  @include screen-medium-up {
    grid-column: 1 / span 3;
  }

  @include screen-large-up {
    grid-column: 1 / span 4;
  }
}

.site-footer__about-link-wrapper {
  @include indent-top(20);
  @include typo-make-sizes($site-footer-small-text-sizes);
  grid-column: 1 / span 2;

  @include screen-small-down {
    grid-row: 1;
    margin-top: 8px;
  }

  @include screen-medium-only {
    grid-column: 2 / span 3;
    margin-top: 0;
  }

  @include screen-medium-down {
    text-align: right;
    margin-top: -4px;
  }

  @include screen-large-up {
    grid-column: 4;
  }

  @include screen-large-up {
    grid-column: span 2;
  }

  & + .site-footer__about-link-wrapper {
    text-align: right;

    @include screen-small-down {
      padding-top: 8px;
      grid-row: 2;
      margin-top: 0;
    }
  }
}

.site-footer__social-link,
.site-footer__menu-link,
.site-footer__about-link {
  @extend %typo-text-link-menu;

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}

.site-footer__about-link {
  @extend %typo-text-link-arrow;

  &:after {
    position: static;
    display: inline-flex;
    align-self: flex-start;
  }

  &:hover {
    @extend %typo-text-link-arrow-hover;
  }
}

.site-footer__button-wrapper {
  @include indent-outer-top(48);
  @include indent-bottom(24);
  @include screen-medium-down {
    display: none;
  }
  @include screen-large-up {
    grid-column: 5 / span 2;
  }
}

.dark-button {
  @extend .button;
  @extend .button--small;
  @extend .button--contrast;
  margin-left: auto;
  cursor: pointer;
  background-color: $color-text;
  display: flex;
}
