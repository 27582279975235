////
/// The component for the useful links section.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/mixins/media-queries" as *;
@use "~@make/ds2/scss/mixins/indent-system" as *;
@use "~@make/ds2/scss/mixins/grid" as *;
@use "~@make/ds2/scss/mixins/typography" as *;
@use "~@make/ds2/scss/variables/colors" as *;
@use "~@make/ds2/scss/variables/typography" as *;
@use "~@make/ds2/scss/placeholders";


.useful-links {
  @include indent-top(64);
  @include indent-bottom(48);
}

.useful-links__list {
  @include grid-standard;
  position: relative;
  display: grid;
}

.useful-links__link {
  @include indent-vertical-pair(20);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column: span 2;

  &:not(:last-child) {
    border-bottom: 1px solid $color-dividing-line-light;
    transition: border-color 0.1s linear;
  }

  &[href^="http"] {
    .useful-links__url {
      @extend %typo-text-link-arrow;
    }

    &:hover {
      .useful-links__url {
        @extend %typo-text-link-arrow-hover;
      }
    }
  }

  &:not([href^="http"]) {
    .useful-links__url {
      @extend %typo-text-link-bullet;
    }
  }

  &:hover {
    border-bottom-color: $color-dividing-line;

    .useful-links__link-heading {
      color: var(--color-interactive-element-hover);
    }

    .useful-links__url {
      color: $color-text;
    }
  }

  &:active {
    .useful-links__link-heading {
      color: $color-interactive-element-active;
    }
  }

  &:focus {
    .useful-links__link-heading {
      @extend %typo-base-text-link-focused;
    }
  }

  @include screen-medium-up {
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
  @include screen-large-up {
    &:nth-last-child(3) {
      border-bottom: none;
    }
  }
}

.useful-links__link-content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 0;
}

.useful-links__link-icon {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center;
}

.useful-links__link-content {

}

.useful-links__link-heading {
  @extend %typo-base-text-link-transition;
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  display: block;
  font-weight: 500;
  outline-color: transparent;
}

.useful-links__url {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  position: relative;
  margin-top: 4px;
  color: $color-hint;
  transition: color 0.1s linear;
}

.useful-links .spoiler:not(.opened) {
  @include screen-small-down {
    .useful-links__link:nth-child(n + 6) {
      border-bottom: none;
    }
  }
  @include screen-medium-up {
    .useful-links__link:nth-child(n + 5) {
      border-bottom: none;
    }
  }
  @include screen-large-up {
    .useful-links__link:nth-child(n + 4) {
      border-bottom: none;
    }
  }
}
