////
/// Styles for the person card component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/text-field";
@use "~@make/ds2/scss/components/button";
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";


.person-info {
  &[href] {
    &:hover {
      .person-info__name {
        color: var(--color-interactive-element-hover);
      }
    }

    &:active {
      .person-info__name {
        color: var(--color-interactive-element-active);
      }
    }

    &:focus {
      .person-info__name {
        @extend %typo-base-text-link-focused;
        outline-color: var(--color-interactive-element-rgba);
      }
    }
  }
}

.person-info__content-wrapper {
  display: flex;
  gap: 0;
}

.person__subordinates > .person__subordinate > .person-info__item-link {
  display: inline-flex;
  gap: 0;
  border: none;
  color: $color-text;

  &:before {
    display: none;
  }

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }

  &:focus {
    @extend %typo-base-text-link-focused;
    outline-color: var(--color-interactive-element-rgba);
  }
}

.person-info__photo-wrapper {
  @include indent-base(80, width height);
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  background-color: $color-light-gray-fill;
  border-radius: 2px;
  @include indent-outer-right(16);
}

.person-info__name {
  @extend %typo-base-text-link-transition;
  @include typo-make-sizes($typo-heading-h4-sizes, $isHeading: true);
  flex-grow: 0;
  outline-color: transparent;
}

.person-info__surname {
  display: flex;
  font-weight: 600;
}

.person-info__position {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  padding-top: 4px;
  color: $color-hint;
}
