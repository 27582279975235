////
/// Common styles for all demo pages.
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;

@use "./variables/colors";
@use "./reset";
//@use "~@make/ds2/scss/reset";
@use "~@make/ds2/scss/layout";
@use "~@make/ds2/scss/typography";
@use "~@make/ds2/scss/global";
@use "~@make/ds2/scss/debug";


//@import "./variables/color_scheme";


.page {
  background-color: $color-light-gray-fill;
}
