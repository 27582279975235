@use "../../../../node_modules/@make/ds2/scss/variables" as *;

.constructor-textarea__wrapper {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: '';
    pointer-events: none;
  }

  &:before {
    width: 12px;
    height: 12px;
    bottom: 0;
    right: 0;
    background-color: $color-white;
    border-radius: 2px;
  }

  &:after {
    width: 8px;
    height: 8px;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
    bottom: 4px;
    right: 4px;
  }
}

.constructor-textarea-default {
  display: flex;
  resize: vertical;
  width: 100%;
  word-break: break-all;
}
