@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

@use "constructor_components/constructor-component-wrapper";
@use "constructor_components/contact_components/contact_person";
@use "constructor_components/contact_components/contacts_with_map";
@use "constructor_components/document_components/document_preview";
@use "constructor_components/form_components/form_in_text";
@use "constructor_components/images_components/image_in_text";
@use "constructor_components/text_components/paragraph";
@use "constructor_components/text_components/text_list";
@use "constructor_components/text_components/text_list_item";
@use "constructor_components/text_components/titles";
@use "constructor_components/text_components/quote";
@use "constructor_components/text_components/spoiler_with_name";
@use "constructor_components/text_components/spoiler";
@use "constructor_components/text_components/interview";
@use "constructor_components/text_components/stages";
@use "constructor_components/text_components/announce";
@use "constructor_components/video_components/video_in_text";
@use "constructor_components/separators/live_separator";


.component-row {
  @include indent-bottom(20);
}

.component-row--password {
  position: relative;
}

.component-row--password-button {
  position: absolute;
  bottom: 31px;
  right: 10px;
  height: 24px;
  cursor: pointer;
}

.component-row-2-columns {
  display: grid;
  @include grid-medium-up(1 1);
  @include indent-bottom(20);
  gap: 40px;
}
