@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";
@use "~@make/ds2/scss/typography";

.auth-form__wrapper {
  display: grid;
  @include grid-large-up(4 4 4);
  @include indent-vertical-pair(32);
  min-height: 100vh;

  .page__section-description {
    &.page__section-description {
      text-align: center;
    }
  }
}

.auth-form {
  grid-column: span 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include screen-large-up {
    grid-column: 2 / span 1;
  }

  .auth-form__header-icon {
    @include indent-outer-bottom(24);
  }
}

.auth-form__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include indent-outer-bottom(48);
}

.auth-form__title {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
}

.auth-form__description {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
}

.auth-form__button-wrapper {
  display: grid;
  @include grid-small-up(1 2 1);

  button {
    grid-column: 2;
  }
}