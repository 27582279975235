@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.announce-block-wrapper {
  .constructor-component__wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}

.announce-block {
  background: white;
  padding-top: 32px;
  padding-bottom: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #2B2B3C;
  margin-bottom: 80px;

  .page-section {
    padding-bottom: 0;
  }
}

.announce-block-content {
  @include grid-standard;
  display: grid;
  @include screen-medium-up {
    grid-template-columns: 4fr 2fr;
  }
}