////
/// Styles for the page-header component.
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/components/text-field";
@use "~@make/ds2/scss/components/button";
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";


.page-header {
  @include grid-container;
  @include indent-top(32);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .back-link {
    @include indent-outer-bottom(20);
    order: 0;
  }

  h1 {
    order: 1;
  }

  h2 + .lead-big {
    margin-top: 4px;
  }

  .person--detail-card {
    @include indent-bottom(64);
    width: 100%;
  }
}
