@use "../../../../node_modules/@make/ds2/scss/variables" as *;

.constructor-checkbox__wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.constructor-checkbox {
  width: 24px;
  height: 24px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.15s ease;
  margin-right: 16px;
  border-radius: 1px;

  &.bordered {
    border: 1px solid $color-dividing-line;
  }

  &.checked {
    background-color: $color-interactive-element;
  }

  &:not(.admin-checkbox) {
    &.checked {
      background-color: var(--color-interactive-element);
    }
  }
}

.constructor-checkbox__icon {
  width: 13px;
  height: 9px;
}

.constructor-checkbox__label {
  font-size: 16px;
  line-height: 24px;

  a {
    color: rgb(0, 80, 178);

    &:hover {
      color: rgba(0, 80, 178, 0.86);
    }
  }
}
