@use '~@make/ds2/scss/variables' as *;
@use '~@make/ds2/scss/mixins' as *;

.constructor-accordion.opened {
  .accordion__icon {
    transform: rotate(-180deg);
  }
}

.constructor-accordion:before {
  display: none;
}

.constructor-accordion .accordion__wrapper {
  padding: 0;
  margin-right: 0;
  margin-left: 0;
}

.constructor-accordion .accordion__button {
  width: fit-content;
  border: none;
}

.constructor-accordion .accordion__text {
  @include indent-outer-right(28);
  font-weight: 700;
}

.constructor-accordion .accordion__icon {
  width: 10px;
  height: 6px;
  align-self: center;
  transition: transform 0.15s ease;
}

.constructor-accordion .accordion__content {
  padding-top: 0;
}

.constructor-accordion .constructor-accordion__empty-text {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  color: $color-hint;
  @include indent-outer-bottom(24);
}
