////
/// The "Press service section" component.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";


.press-service__wrapper {
  @include indent-outer-bottom(-80);
  border-top: 1px solid $color-dividing-line;
}

.press-service__list {
  @include indent-outer-bottom(-32);
  @include indent-bottom(64);

  .press-service__contact:last-child {
    border-bottom: 0;
  }
}

// TODO: refactor
.press-service-contacts-list {
  .press-service__contact:first-child {
    padding-top: 0;
  }

  .press-service__contact:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.press-service__contact {
  @include indent-vertical-pair(32);
  border-bottom: 1px solid $color-dividing-line-light;
  transition: border-color 0.1s linear;

  @include screen-small-down {
    display: flex;
    flex-direction: column;
  }

  @include screen-medium-up {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }

  @include screen-large-up {
    grid-template-columns: repeat(12, 1fr);
  }

  &:hover {
    border-color: $color-dividing-line;
  }

  .contact__info {
    grid-column: span 5;
    @include screen-large-up {
      grid-column: span 6;
    }

    & + .contact__photo {
      @include screen-large-up {
        grid-column: 10 / span 3;
      }
    }
  }

  .contact__title {
    @include indent-outer-bottom(24);

    @include screen-small-down {
      margin-bottom: 20px;
    }
  }
}

.press-service__contact--main {
  .contact__name {
    @include indent-outer-bottom(16);
    @include typo-make-sizes($typo-heading-h2-sizes, $isHeading: true);
    color: $color-heading;
  }

  .contact__title {
    @include indent-outer-bottom(32);
  }
}

.contact__name {
  @include indent-outer-bottom(12);
  @include typo-make-sizes($typo-heading-h3-sizes, $isHeading: true);
}

.contact__last-name {
  font-weight: 600;
}

.contact__surname {
  display: flex;
  font-weight: 600;
}

.contact__contacts-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact__phone {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  @extend %typo-base-text-link;
  color: $color-text;

  & + .contact__text {
    @include indent-outer-top(24);
  }
}

.contact__text {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  color: $color-text;
}

.contact__mail {
  @include indent-outer-top(12);
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  @extend %typo-base-text-link;
}

.contact__photo {
  grid-column: span 3;
  width: 100%;
  padding-top: 100%;
  position: relative;

  @include screen-small-down {
    margin-bottom: 20px;
    order: -1;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
