////
/// Styles for the article page.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";
@use "~@make/ds2/scss/typography";


.photo-modal {
  @include indent-vertical-pair(4);
  @include indent-horizontal-pair(4);
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  z-index: -2;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-dividing-line, 0.8);
    opacity: 0;
    transition: opacity 0.15s linear;
    z-index: -1;
    content: "";
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: transform 0.25s ease-out 0.1s, top 0.25s ease-out 0.1s, opacity 0.1s linear;
    transform: translateY(50%);
    opacity: 0;
    content: "";
  }

  &.active {
    top: 0;
    z-index: 11 !important;
    pointer-events: auto;

    &:before {
      opacity: 1;
    }

    &:after,
    .photo-modal__wrapper {
      transition-delay: 0s, 0s;
      transform: translateY(0);
      opacity: 1;
    }

    .photo-modal__icon-button {
      z-index: 1;
    }
  }
}

.photo-modal__wrapper {
  @extend %helper-hidden-system-scrollbar;
  @include indent-outer-vertical-pair(4);
  @include indent-vertical-pair(12);
  @include grid-standard;
  display: grid;
  position: relative;
  height: calc(100% - 8px);
  background-color: $color-light-gray-fill;
  transition: transform 0.25s ease-out 0.1s, opacity 0.1s linear;
  opacity: 0;

  @include screen-small-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  @include screen-medium-down {
    grid-template-columns: repeat(8, 1fr);
  }
}

.photo-modal__icon-button {
  @include indent-outer-horizontal-pair(24);
  @include indent-outer-vertical-pair(24);
  position: absolute;
  right: 0;
  top: 5px;
  display: flex;
  align-items: center;

  @include screen-small-down {
    right: 0 !important;
  }

  .icon {
    @include screen-small-down {
      fill: white;
      width: 20px;
      height: 20px;
    }
  }
}

.photo-modal__slider,
.photo-modal__single-photo {
  grid-column: span 5;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.photo-modal__single-photo {
  display: flex;
  flex-direction: column;
  align-items: center;

  .photo-slide {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.photo-modal__slider {
  @include screen-small-down {
    grid-column: span 8;
  }

  @include screen-medium-only {
    @include indent-outer-right(-40);
    grid-column: span 5;
  }

  &:hover {
    .photo-slider__button--prev,
    .photo-slider__button--next {
      opacity: 1;
    }
  }
}

.photo-slider__list {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  transition-property: transform;
  box-sizing: content-box;
  gap: 0;

  @include screen-medium-down {
    align-items: center;
  }

  .swiper-slide {
    flex-shrink: 0;
    position: relative;
    padding-top: 56.25%;
  }
}

.photo-slide {
  display: flex;
  width: 100%;
  height: 56.25%;
  position: relative;
  padding-top: 56.25%;
  background-color: $color-dividing-line-light;
  transition: height 0.2s ease-in-out;

  @include screen-medium-down {
    height: auto;
  }

  &.photo-slide--vertical {
    @include screen-small-down {
      padding-top: 0;
      height: 100%;
    }

    img {
      object-fit: contain;
      @include screen-small-down {
        object-position: top center;
        width: 100%;
        height: 100%;
      }

    }
  }
}

.photo-slide__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;

  @include screen-large-up {
    object-fit: contain;
  }
}

.photo-slider__button--prev,
.photo-slider__button--next {
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: calc(50% - 24px);
  box-shadow: 0 2px 4px rgba(0, 0, 30, 0.08);
  transition: box-shadow 0.1s linear, opacity 0.1s linear;
  opacity: 0;

  @include screen-small-down {
    display: none;
  }

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 30, 0.24);

    .icon {
      fill: $color-heading;
    }
  }

  &.swiper-button-disabled {
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .icon {
    fill: $color-text;
    transition: fill 0.1s linear;
  }
}

.photo-slider__button--prev {
  @include indent-outer-left(24);
  left: 0;
}

.photo-slider__button--next {
  @include indent-outer-right(24);
  right: 0;
}

.photo-modal__slider-description-wrapper {
  @include indent-vertical-pair(24);
  @include indent-horizontal-pair(32);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0;
  grid-column: span 1;
  min-width: 320px;

  @include screen-small-down {
    grid-column: span 8;
    width: 100%;
    flex-grow: 1;
  }

  @include screen-medium-only {
    grid-column: span 3;
  }

  @include screen-medium-up {
    @include indent-outer-vertical-pair(-12);
  }

  .article__buttons-wrapper {
    @include screen-medium-up {
      justify-content: flex-start;
    }
  }
}

.photo-modal__pagination-wrapper {
  @include indent-outer-bottom(32);
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
}

.photo-modal__pagination {
  color: $color-text;
  display: inline-block;
}

.photo-modal__slide-description {
  @include indent-outer-bottom(32);
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
}

.photo-modal__slide-contoller {
  display: flex;
  align-items: center;

  @include screen-small-down {
    display: none;
  }

  @include screen-medium-down {
    @include indent-outer-bottom(32);
  }
}

.photo-modal__slide-type {
  @include indent-outer-left(24);
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
}

.photo-modal__album-name {
  @include indent-outer-bottom(12);
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
}

.photo-modal__album-title {
  @extend %typo-base-text-link;
  @extend %typo-text-link-external-url-arrow;
  @include typo-make-sizes($typo-heading-h4-sizes, $isHeading: true);
  display: inline-flex;
  font-weight: 500;
  color: $color-heading;

  &:not(:last-child) {
    @include indent-outer-bottom(64);
  }
}

.photo-modal__download-image--mobile {
  @include screen-medium-up {
    display: none;
  }
}
