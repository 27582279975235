////
/// Styles for the construct page.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";
@use "~@make/ds2/scss/typography";

.page-construct__header {
  @include indent-top(48);
  @include indent-bottom(32);
}

.page-construct {
  background-color: $color-light-gray-fill;

  h1 {
    font-weight: 600;
  }

  .article__content {
    @include screen-large-up {
      grid-column: 1 / span 4;
    }
  }

  .article__image {
    margin-right: 0;
  }

  > img:not([class]) + .image-caption,
  img + .image-caption,
  .article__image-wrapper + .image-caption,
  .youtube-player + .image-caption {
    @include screen-large-up {
      margin-left: 0;
      margin-right: 0;
    }
  }

  input {
    height: 56px;
  }

  .constructor-button {
    height: 56px;
    margin-top: auto;
  }
}

.page-construct__wrapper {
  display: grid;
  @include grid-standard;
  @include indent-top(32);
}

.page-construct__content {
  grid-column: span 6;
  @include screen-medium-up {
    grid-column: span 4;
  }
}

.page-construct__content-block {
  .person__subordinate {
    &:not(:last-child) {
      border-bottom: 1px solid $color-dividing-line-light;
      @include indent-bottom(20);
      @include indent-outer-bottom(20);
    }

    .person-info__photo-wrapper {
      width: 48px;
      height: 48px;
    }

    .person-info__wrapper {
      padding-left: 0;

      &:not(:only-child) {
        @include screen-small-down {
          width: calc(100% - 60px);
        }
      }
    }

    .person-info__photo-wrapper {
      @include indent-outer-right(16);
    }

    .person-info {
      flex-wrap: wrap;
    }

    .person-info__title {
      @include indent-outer-bottom(16);
    }

    .person-info__name {
      @include typo-make-sizes($typo-paragraph-nav-sizes);
    }

    .person-info__surname {
      display: inline;
    }
  }
}

.region__content-wrapper {
  display: grid;
  @include grid-standard;
  @include indent-top(32);
}

.region__content {
  grid-column: span 6;
  @include screen-medium-up {
    grid-column: span 4;
  }

  p {
    @include indent-outer-bottom(24);
  }

  & > section:not(:last-child) {
    @include indent-bottom(80);
  }

  .document-viewer__item-content {
    align-items: flex-start;

    .icon {
      margin-left: 0;
    }
  }

  .accordion {
    margin-left: -$grid-gutter-small;
    margin-right: -$grid-gutter-small;

    @include screen-large-up {
      margin-left: -$grid-gutter-large;
      margin-right: -$grid-gutter-large;
    }

    @include screen-huge-up {
      margin-left: -$grid-gutter-huge;
      margin-right: -$grid-gutter-huge;
    }

    .important-links {
      @include grid-medium-up(1);
    }

    .important-links__item {
      display: flex;
      gap: 0;
      align-items: center;

      @include screen-medium-up {
        &:nth-last-child(2):not(:first-child) {
          .important-links__link:before {
            display: block;
          }
        }
      }
    }

    .important-links__link {
      flex: 1;
      @include typo-make-sizes($typo-paragraph-text-sizes);
    }
  }
}