////
/// Styles for the site share modal window component.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders";

.share__title {
  @include indent-inner-bottom(24);
}

.share__social-links {
  @include indent-top(24);
  display: flex;
  gap: 0;

  @include screen-medium-only {
    justify-self: end;
  }
  @include grid-huge-up(1 1) {
    display: grid;
    gap: inherit;
    padding-top: 24px;
    margin-bottom: -12px;
  }
}

.share-link__social-link-item {
  display: inline-flex;
  margin-right: 12px;

  @include screen-huge-up {
    &:nth-child(2n) {
      margin-left: -$grid-gutter-huge * 0.5;
    }

    margin-right: 0;
    margin-bottom: 12px;
  }

  @include screen-min($grid-container-content-huge-max) {
    &:nth-child(2n) {
      margin-left: -$grid-gutter-huge;
    }
  }
}

.share-link {
  @extend %typo-text-link-menu;
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  display: flex;
  align-items: center;
  gap: 0;

  .icon {
    @include indent-outer-right(20);
  }

  &:hover {
    color: var(--color-interactive-element);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}

.share-link__social-link-text {
  display: none;

  @include screen-huge-up {
    display: inline;
  }
}

.share__button-wrapper {
  @include indent-top(24);
}
