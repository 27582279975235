@use "node_modules/@make/ds2/scss/variables" as *;
@use "node_modules/@make/ds2/scss/mixins" as *;

.constructor-file-uploader {
  display: flex;
  border: 1px solid $color-dividing-line;
  border-radius: 2px;
  padding: 26px 24px;
  align-items: center;
  gap: 0;

  .button {
    flex-shrink: 0;
  }

  &.error {
    box-shadow: 0 0 0 2px #e52e2f;
    margin-top: 7px;
  }
}

.constructor-file-uploader__name {
  font-size: 14px;
  line-height: 20px;
  margin-left: 16px;
  color: $color-hint;
  max-width: 300px;
  overflow: hidden;
}

.constructor-file-uploader__name--selected {
  color: $color-text;
}

.constructor-file-uploader__file {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.constructor-file-uploader__wrapper {
  display: grid;
  @include grid-medium-up(1 1);
  gap: 20px;

  &.with-file {
    @include grid-medium-up(1 5);
  }
}

.constructor-file-uploader__preview {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.constructor-file-uploader__delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: $color-error;
}

.constructor-file-uploader__delete-icon {
  width: 24px;
  height: 24px;
  fill: #fff;
}

.constructor-file-progressbar__wrapper {
  margin-top: 16px;
}

.constructor-file-progressbar__block {
  opacity: 0;

  &.active {
    opacity: 1;
  }
}