@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.page-public {
  background-color: $color-light-gray-fill;
  overflow: hidden;
}

.page-public--main {
  .constructor-component__wrapper {
    padding: 0;
    @include indent-outer-horizontal-pair(40);
    position: relative;
  }
}

.page-public--wrapper {
  min-height: 100vh;
}

.constructor-page {
  .page-public {
    background-color: $color-light-gray-fill;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    @include screen-small-up {
      max-width: $grid-container-content-small-max;
    }

    @include screen-medium-up {
      max-width: $grid-container-content-medium-max;
    }

    @include screen-large-up {
      max-width: $grid-container-content-large-max;
    }

    @include screen-huge-up {
      max-width: $grid-container-content-huge-max;
    }
  }
}

.top-bar + .page-public,
.top-bar + .page-article {
  @include indent-outer-top(58);
  border-top: 1px solid $color-dividing-line-light;
}


